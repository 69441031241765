import Loading from '../../components/commons/Loading';
import useManageBranchesController from '../../hooks/controllers/useManageBranchesController';
import CreateBranchForm from './components/CreateBranchForm';
import ListBranch from './components/ListBranch';

const ManageBranchesPage = () => {
  const {
    globalLoading,
    methods,
    sellerList,
    createUser,
    addUserLoading,
    branchList,
    deleteUser,
    updateUser,
  } = useManageBranchesController();

  if (globalLoading) {
    return <Loading />;
  }

  return (
    <>
      <CreateBranchForm methods={methods} branchList={branchList} onSubmit={createUser} loading={addUserLoading} />
      <ListBranch sellerList={sellerList} onDelete={deleteUser} branchList={branchList} onSubmitEdit={updateUser} />
    </>
  );
};

export default ManageBranchesPage;
