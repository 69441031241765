/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { manageBranchesSchemaValidation } from '../../forms/manageBranchesSchemaValidations';
import { useToaster } from '../../contexts/ToasterContext';
import { getApiErrorMessage } from '../../services/errorApiService';
import {
  createBranchUser,
  deleteBranchUser,
  getBranchUserList,
  updateBranchUser,
} from '../../api/commerceAffiliationApiService';
import i18n from '../../common/i18n';
import { getBranches } from '../../api/loanRequestApiService';

const useManageBranchesController = () => {
  const methods = useForm({
    resolver: yupResolver(manageBranchesSchemaValidation),
  });

  const { showToast } = useToaster();

  const [globalLoading, setGlobalLoading] = useState(true);
  const [addUserLoading, setAddUserLoading] = useState(false);
  const [sellerList, setSellerList] = useState();
  const [branchList, setBranchrList] = useState();

  const getAllUsers = async () => {
    try {
      const { data: branchListAux } = await getBranches();
      setBranchrList(branchListAux.map((branch) => ({ id: branch.id, label: branch.description })));

      const { data: userListAux } = await getBranchUserList();
      userListAux.map((user) => {
        user.branch = (branchListAux.find((branch) => branch.id === user.branchId)).description;
        return user;
      });
      setSellerList(userListAux);
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    }
  };

  const createUser = async (data) => {
    try {
      setAddUserLoading(true);
      const user = {
        identificationNumber: data.identifier,
        firstname: data.name,
        lastname: data.surname,
        email: data.email,
        phone: data.phone,
        profile: data.profile,
        branchId: data.branch.id,
        branchName: branchList.find((branch) => branch.id === data.branch.id).label,
      };
      await createBranchUser(user);
      showToast(i18n.ManageBranchesPage.userCreated, 'success');
      await getAllUsers();
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    } finally {
      setAddUserLoading(false);
    }
  };

  const updateUser = async (data) => {
    try {
      console.log(data);
      setAddUserLoading(true);
      const user = {
        identificationNumber: data.identifier,
        firstname: data.name,
        lastname: data.surname,
        email: data.email,
        phone: data.phone,
        profile: data.profile,
        branchId: data.branch.id,
        branchName: branchList.find((branch) => branch.id === data.branch.id).label,
      };
      await updateBranchUser(user, data.id);
      showToast(i18n.ManageBranchesPage.userEdited, 'success');
      await getAllUsers();
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    } finally {
      setAddUserLoading(false);
    }
  };

  const deleteUser = async (data) => {
    try {
      await deleteBranchUser(data.id);
      showToast(i18n.ManageBranchesPage.userDeleted, 'success');
      await getAllUsers();
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    }
  };

  const init = async () => {
    try {
      setGlobalLoading(true);
      const { data: branchListAux } = await getBranches();
      setBranchrList(branchListAux.map((branch) => ({ id: branch.id, label: branch.description })));

      await getAllUsers();
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    } finally {
      setGlobalLoading(false);
    }
  };

  useEffect(() => { init(); }, []);

  return {
    globalLoading,
    methods,
    sellerList,
    createUser,
    addUserLoading,
    branchList,
    deleteUser,
    updateUser,
  };
};

export default useManageBranchesController;
