import { useEffect } from 'react';
import Loading from '../../components/commons/Loading';
import useRedirectController from '../../hooks/controllers/useRedirectController';

const RedirectPage = () => {
  const { goNextStep } = useRedirectController();

  useEffect(() => { goNextStep(); }, []);

  return (
    <Loading />
  );
};

export default RedirectPage;
