export const LOGIN_ERROR_CODE_E001 = 'E001';
export const LOGIN_ERROR_CODE_E002 = 'E002';
export const LOGIN_ERROR_CODE_E003 = 'E003';
export const LOGIN_ERROR_CODE_E004 = 'E004';
export const LOGIN_ERROR_CODE_E005 = 'E005';
export const LOGIN_ERROR_CODE_E006 = 'E006';
export const LOGIN_ERROR_CODE_E007 = 'E007';
export const LOGIN_ERROR_CODE_E008 = 'E008';
export const LOGIN_ERROR_CODE_E009 = 'E009';
export const LOGIN_ERROR_CODE_E010 = 'E010';
export const LOGIN_ERROR_CODE_E011 = 'E011';
export const LOGIN_ERROR_CODE_E012 = 'E012';
export const LOGIN_ERROR_CODE_E013 = 'E013';
export const LOGIN_ERROR_CODE_E014 = 'E014';
export const LOGIN_ERROR_CODE_E015 = 'E015';
export const LOGIN_ERROR_CODE_E016 = 'E016';
export const LOGIN_ERROR_CODE_E017 = 'E017';
export const LOGIN_ERROR_CODE_EMAIL_IS_NOT_VALID = 'EMAIL_IS_NOT_VALID';
export const LOGIN_ERROR_CODE_EXPIRED_EXECUTE_USER_PASSWORD_RESET = 'EXPIRED_EXECUTE_USER_PASSWORD_RESET';
export const LOGIN_ERROR_CODE_GENERIC_ERROR = 'GENERIC_ERROR';
export const LOGIN_PASSWORD_EXPIRED = 'LOGIN_PASSWORD_EXPIRED';

export const ERROR_CODE_SAPP_NO_PEN_CREDITS = 'SAPP_NO_PEN_CREDITS';
export const ERROR_CODE_SAPP_NO_LINES = 'SAPP_NO_LINES';

export const ERROR_SENDING_EMAIL = 'ERROR_SENDING_EMAIL';

export const SELFIE_REJECTED_BY_RENAPER = 'SELFIE_REJECTED_BY_RENAPER';
export const DNI_BARCODE_NOT_FOUND = 'DNI_BARCODE_NOT_FOUND';
export const DNI_BARCODE_NOT_FOUND_EXCEED_LIMIT = 'DNI_BARCODE_NOT_FOUND_EXCEED_LIMIT';

export const ERROR_REQUESTED_AMOUNT_IS_GREATER_THAN_MAX_OFFER = 'ERROR_REQUESTED_AMOUNT_IS_GREATER_THAN_MAX_OFFER';

export const ERROR_ALREADY_EXIST_CANCEL_CREDIT = 'ERROR_ALREADY_EXIST_CANCEL_CREDIT';
