import { fetch } from './axiosInstance';
import { CREDICUOTAS_COMMERCE_BACKEND_URL, getEnv } from './env';

export const getBatchesSettlements = () =>
  fetch({
    url: '/settlements/getBatchesSettlements',
    method: 'get',
  });

export const generateBatchSettlementPdf = (settlementId) =>
  window.open(`${getEnv(CREDICUOTAS_COMMERCE_BACKEND_URL)}/settlements/generateBatchSettlementPdf/${settlementId}`);

export const getSettledLoans = (filters) =>
  fetch({
    url: '/settlements/getSettledLoans',
    method: 'get',
    params: filters,
  });

export const generateSettledLoansExcel = (filters) =>
  fetch({
    url: '/settlements/generateSettledLoansExcel',
    method: 'get',
    params: filters,
    responseType: 'blob',
  });

export const abortLoanApi = (loan) =>
  fetch({
    url: '/settlements/abortLoan',
    method: 'post',
    data: {
      sappCreditId: loan.sappLoanId,
      customerDocumentNumber: loan.customerId,
      customerName: loan.customerName,
    },
  });
