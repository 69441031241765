import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Box, Grid, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { Button } from '@doit/pcnt-react-ui-library';
import TextInputControlled from '../../../components/commons/TextInputControlled';
import i18n from '../../../common/i18n';
import { sendOfferSchemaValidation } from '../../../forms/installmentPlanSchemaValidations';

const InstallmentPlanSendOffer = ({ onSubmit, loading }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const methods = useForm({
    resolver: yupResolver(sendOfferSchemaValidation),
  });
  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container sx={{ marginTop: '32px', borderLeft: '5px solid #E0E0E0', paddingLeft: '24px' }}>
            <Grid item xs={12}>
              <Typography align="left" sx={{ fontSize: '16px', fontWeight: 600 }}>{i18n.InstallmentPlanPage.sendOffer.title}</Typography>
              <Box
                sx={{
                  marginTop: '24px',
                  display: { xs: 'block', md: 'flex' },
                  alignItems: { xs: '', md: 'center' },
                  textAlign: { xs: 'left', md: '' },
                }}
              >
                <TextInputControlled
                  name="cellphone"
                  label={i18n.InstallmentPlanPage.sendOffer.input}
                  variant="outlined"
                  mandatory
                  fullWidth={isMobile}
                />
                <Button
                  variant="tertiary"
                  type="submit"
                  endIcon={<img src="/images/send-icon.svg" alt="enviar" />}
                  loading={loading}
                  sx={{ marginTop: { xs: '16px', md: '0px' } }}
                  className={isMobile ? 'minimalist' : ''}
                >
                  {i18n.InstallmentPlanPage.sendOffer.cta}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

InstallmentPlanSendOffer.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

InstallmentPlanSendOffer.defaultProps = {
  loading: false,
};

export default InstallmentPlanSendOffer;
