import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import {
  Dialog,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { Button } from '@doit/pcnt-react-ui-library';
import i18n from '../../../common/i18n';
import { fillMessageWith } from '../../../utils/functionsUtils';

const LoanRequestSendOfferModal = ({
  open, onClose, onConfirm, loanRequests,
// eslint-disable-next-line arrow-body-style
}) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
      >
        <Grid container sx={{ padding: '32px', paddingTop: '24px' }}>
          <Grid item xs={12} sx={{ textAlign: 'right' }}><IconButton sx={{ zIndex: 100 }} onClick={onClose}><Close /></IconButton></Grid>
          <Grid item xs={12}>
            <Typography align="left" sx={{ fontSize: '20px', fontWeight: 700 }}>
              {i18n.LoanRequestSendOfferModal.title}
            </Typography>
            {loanRequests?.length > 1 ? (
              <Typography align="left" sx={{ fontSize: '14px', marginTop: '4px' }}>
                {parse(fillMessageWith(i18n.LoanRequestSendOfferModal.description[1], loanRequests.length))}
              </Typography>
            ) : (
              <Typography align="left" sx={{ fontSize: '14px', marginTop: '4px' }}>
                {parse(fillMessageWith(i18n.LoanRequestSendOfferModal.description[0], loanRequests[0].customerName.toLowerCase()))}
              </Typography>
            )}
            <Button
              variant="primary"
              color="grey"
              fullWidth
              onClick={onConfirm}
              sx={{ marginTop: '40px' }}
            >
              {loanRequests.length > 1 ? i18n.LoanRequestSendOfferModal.cta[1] : i18n.LoanRequestSendOfferModal.cta[0]}
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

LoanRequestSendOfferModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  loanRequests: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

LoanRequestSendOfferModal.defaultProps = {
  open: false,
};

export default LoanRequestSendOfferModal;
