import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { Divider } from '@doit/pcnt-react-ui-library';
import InstallmentPlanHeaderSection from './InstallmentPlanHeaderSection';
import StepHeader from './StepHeader';
import InstallmentPlanRequiredDocuments from './InstallmentPlanRequiredDocuments';
import { isProductToPayVehicle } from '../../../utils/loanRequestUtils';
import InstallmentPlanPledgeSection from './InstallmentPlanPledgeSection';
import InstallmentPlanSendOffer from './InstallmentPlanSendOffer';
import InstallmentPlanInputAmountForm from './InstallmentPlanInputAmountForm';
import InstallmentPlanList from './InstallmentPlanList';
import StepsLoanRequest from './StepsLoanRequest';
import i18n from '../../../common/i18n';

const InstallmentPlanMobile = ({
  loanRequest,
  doReload,
  installments,
  selectedInstallment,
  setSelectedInstallment,
  brandList,
  modelList,
  brand,
  model,
  onChangeBrand,
  vehicleModelLoading,
  onChangeModel,
  maximumLoanable,
  sendOffer,
  sendOfferLoading,
  getInstallments,
  installmentsLoading,
  requestedAmount,
  advanceAmount,
  choseInstallment,
  selectInstallmentLoading,
}) => (
  <>
    <StepsLoanRequest stepActive={i18n.StepsLoanRequest[1]} />
    <Grid container sx={{ paddingLeft: '24px', paddingRight: '24px', backgroundColor: '#FFF' }}>
      <Grid item xs={12}>
        <InstallmentPlanHeaderSection loanRequest={loanRequest} onReload={doReload} />
        <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
        <StepHeader loanRequest={loanRequest} />

        {/* DOCUMENTOS REQUERIDOS */}
        <InstallmentPlanRequiredDocuments loanRequest={loanRequest} />

        {/* FORMULARIO PARA ENVIAR OFERTA AL CLIENTE */}
        <InstallmentPlanSendOffer onSubmit={sendOffer} loading={sendOfferLoading} />

        <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />

        {/* SECCION MARCA Y MODELO DE PRENDARIOS */}
        {isProductToPayVehicle(loanRequest) && (
          <InstallmentPlanPledgeSection
            brandList={brandList}
            modelList={modelList}
            brand={brand}
            model={model}
            onChangeBrand={onChangeBrand}
            vehicleModelLoading={vehicleModelLoading}
            onChangeModel={onChangeModel}
            maximumLoanable={maximumLoanable}
          />
        )}

        {/* SECCION INGRESO DE MONTO */}
        <InstallmentPlanInputAmountForm
          onSubmit={getInstallments}
          loading={installmentsLoading}
          canBack={!(installments && installments.length > 0)}
        />

        <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />

        {/* SECCION LISTADO DE CUOTAS */}
        {installments && installments.length > 0 && (
          <InstallmentPlanList
            installments={installments}
            selectedInstallment={selectedInstallment}
            setSelectedInstallment={setSelectedInstallment}
            requestedAmount={requestedAmount}
            advanceAmount={advanceAmount}
            onSubmit={choseInstallment}
            loading={selectInstallmentLoading}
          />
        )}
      </Grid>
    </Grid>
  </>
);

InstallmentPlanMobile.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
  doReload: PropTypes.func.isRequired,
  installments: PropTypes.arrayOf(PropTypes.shape()),
  selectedInstallment: PropTypes.shape(),
  setSelectedInstallment: PropTypes.func.isRequired,
  brandList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  modelList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  brand: PropTypes.number,
  model: PropTypes.number,
  onChangeBrand: PropTypes.func.isRequired,
  vehicleModelLoading: PropTypes.bool,
  onChangeModel: PropTypes.func.isRequired,
  maximumLoanable: PropTypes.number,
  sendOffer: PropTypes.func.isRequired,
  sendOfferLoading: PropTypes.bool,
  getInstallments: PropTypes.func.isRequired,
  installmentsLoading: PropTypes.bool,
  requestedAmount: PropTypes.number,
  advanceAmount: PropTypes.number,
  choseInstallment: PropTypes.func.isRequired,
  selectInstallmentLoading: PropTypes.bool,
};

InstallmentPlanMobile.defaultProps = {
  installments: [],
  selectedInstallment: undefined,
  brand: undefined,
  model: undefined,
  vehicleModelLoading: false,
  maximumLoanable: undefined,
  advanceAmount: undefined,
  sendOfferLoading: false,
  installmentsLoading: false,
  selectInstallmentLoading: false,
  requestedAmount: undefined,
};

export default InstallmentPlanMobile;
