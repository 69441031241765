import { Navigate, Route, Routes } from 'react-router';
import LoggedRoute from './LoggedRoute';
import DashboardLayoutComponent from '../pages/dashboard/components/DashboardLayoutComponent';
import {
  ClubCredicuotas, Comisiones, Home, PanelVentas, Renovadores,
} from '../pages/dashboard/fakePages';
import { privateRoutes } from '../constants/routesConstants';
import RestrictedRoute from './RestrictedRoute';
import { menuRestrictions } from '../utils/menuUtils';
import QuotationPage from '../pages/dashboard/quotationPage';
import InstallmentPlanPage from '../pages/loanRequest/installmentPlanPage';
import CustomerDataPage from '../pages/loanRequest/customerDataPage';
import FinalPage from '../pages/loanRequest/finalPage';
import ManageBranchesPage from '../pages/commerceAffiliation/manageBranchesPage';
import DocumentUploadPage from '../pages/loanRequest/documentUploadPage';
import SignaturePage from '../pages/loanRequest/signaturePage';
import BiometricResultPage from '../pages/loanRequest/biometricResultPage';
import LoanRequestsPage from '../pages/dashboard/loanRequestsPage';
import RedirectPage from '../pages/loanRequest/redirectPage';
import useWebChat from '../hooks/useWebChat';
import SettlementsPage from '../pages/dashboard/SettlementsPage';

const ProtectedRoutes = () => {
  useWebChat();
  return (
    <Routes>
      <Route path="/" element={<LoggedRoute><DashboardLayoutComponent /></LoggedRoute>}>
        <Route
          path={privateRoutes.home}
          element={<RestrictedRoute condition={menuRestrictions[privateRoutes.home]} element={<Home />} />}
        />
        <Route
          path={privateRoutes.newLoanRequest}
          element={<RestrictedRoute condition={menuRestrictions[privateRoutes.newLoanRequest]} element={<QuotationPage />} />}
        />
        <Route
          path={privateRoutes.loanRequests}
          element={<RestrictedRoute condition={menuRestrictions[privateRoutes.loanRequests]} element={<LoanRequestsPage />} />}
        />
        <Route
          path={privateRoutes.renewers}
          element={<RestrictedRoute condition={menuRestrictions[privateRoutes.renewers]} element={<Renovadores />} />}
        />
        <Route
          path={privateRoutes.liquidations}
          element={<RestrictedRoute condition={menuRestrictions[privateRoutes.liquidations]} element={<SettlementsPage />} />}
        />
        <Route
          path={privateRoutes.commission}
          element={<RestrictedRoute condition={menuRestrictions[privateRoutes.commission]} element={<Comisiones />} />}
        />
        <Route
          path={privateRoutes.sales}
          element={<RestrictedRoute condition={menuRestrictions[privateRoutes.sales]} element={<PanelVentas />} />}
        />
        <Route
          path={privateRoutes.credicuotasClub}
          element={<RestrictedRoute condition={menuRestrictions[privateRoutes.credicuotasClub]} element={<ClubCredicuotas />} />}
        />
        <Route
          path={privateRoutes.manageBranches}
          element={<RestrictedRoute condition={menuRestrictions[privateRoutes.manageBranches]} element={<ManageBranchesPage />} />}
        />
        <Route path={privateRoutes.redirect[0]} element={<RedirectPage />} />
        <Route path={privateRoutes.installmentPlan} element={<InstallmentPlanPage />} />
        <Route path={privateRoutes.customerData} element={<CustomerDataPage />} />
        <Route path={privateRoutes.documentUpload} element={<DocumentUploadPage />} />
        <Route path={privateRoutes.biometricResult} element={<BiometricResultPage />} />
        <Route path={privateRoutes.signature} element={<SignaturePage />} />
        <Route path={privateRoutes.final} element={<FinalPage />} />
        <Route path="/" element={<Navigate to={privateRoutes.home} replace />} />
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default ProtectedRoutes;
