import PropTypes from 'prop-types';
import {
  CircularProgress, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, Tooltip, Typography,
} from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import { Divider } from '@doit/pcnt-react-ui-library';
import i18n from '../../../common/i18n';
import { fillMessageWith } from '../../../utils/functionsUtils';
import { moneyFormatter } from '../../../utils/formatterUtils';

const InstallmentPlanPledgeSection = ({
  brandList, modelList, brand, model, onChangeBrand, vehicleModelLoading, onChangeModel, maximumLoanable,
}) => (
  <>
    <Typography align="left" sx={{ fontSize: '20px', fontWeight: 600 }}>{i18n.InstallmentPlanPage.vehicleSelection.title}</Typography>
    <Typography align="left" sx={{ fontSize: '14px', marginTop: '4px', marginBottom: '32px' }}>
      {i18n.InstallmentPlanPage.vehicleSelection.description}
    </Typography>
    <Grid container columnGap="24px" rowGap="20px">
      <Grid item xs={12} md={4}>
        <FormControl fullWidth>
          <InputLabel id="brand-select-label">{i18n.InstallmentPlanPage.vehicleSelection.brand}</InputLabel>
          <Select
            labelId="brand-select-label"
            id="brand-select"
            value={brand || 'none'}
            label={i18n.InstallmentPlanPage.vehicleSelection.brand}
            onChange={onChangeBrand}
          >
            <MenuItem value="none">{i18n.InstallmentPlanPage.vehicleSelection.brandEmptyOption}</MenuItem>
            {brandList.map((brandAux) => (
              <MenuItem value={brandAux.id} key={brandAux.id}>{brandAux.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl fullWidth>
          <InputLabel id="model-select-label">{i18n.InstallmentPlanPage.vehicleSelection.model}</InputLabel>
          <Select
            labelId="model-select-label"
            id="model-select"
            value={model || 'none'}
            label={i18n.InstallmentPlanPage.vehicleSelection.model}
            onChange={onChangeModel}
            startAdornment={
              vehicleModelLoading && (
                <InputAdornment position="start">
                  <CircularProgress size={20} />
                </InputAdornment>
              )
            }
          >
            <MenuItem value="none">{i18n.InstallmentPlanPage.vehicleSelection.modelEmptyOption}</MenuItem>
            {modelList?.map((modelAux) => (
              <MenuItem value={modelAux.codia} key={modelAux.codia}>{modelAux.description}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
    {maximumLoanable && (
      <>
        <Typography
          align="left"
          sx={{
            fontSize: '16px', fontWeight: 600, marginTop: '41px', display: 'flex',
          }}
        >
          {fillMessageWith(i18n.InstallmentPlanPage.vehicleSelection.maxAmount, moneyFormatter(maximumLoanable))}
          <Tooltip title={i18n.InstallmentPlanPage.vehicleSelection.maxAmountTooltip}>
            <HelpOutline sx={{ marginLeft: '10px', color: '#424242' }} />
          </Tooltip>
        </Typography>
        <Typography align="left" sx={{ fontSize: '14px', marginTop: '8px' }}>
          {i18n.InstallmentPlanPage.vehicleSelection.maxAmountHint}
        </Typography>
      </>
    )}
    <Divider sx={{ marginTop: '32px', marginBottom: '32px' }} />
  </>
);

InstallmentPlanPledgeSection.propTypes = {
  brandList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  modelList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  brand: PropTypes.number,
  model: PropTypes.number,
  onChangeBrand: PropTypes.func.isRequired,
  vehicleModelLoading: PropTypes.bool,
  onChangeModel: PropTypes.func.isRequired,
  maximumLoanable: PropTypes.number,
};

InstallmentPlanPledgeSection.defaultProps = {
  brand: undefined,
  model: undefined,
  vehicleModelLoading: false,
  maximumLoanable: undefined,
};

export default InstallmentPlanPledgeSection;
