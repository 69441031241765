import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import i18n from '../../../common/i18n';
import { isMobileOperatingSystem } from '../../../utils/functionsUtils';

const useStyles = makeStyles((theme) => ({
  stepContainer: {
    margin: '20px',
    marginLeft: '68px',
    marginRight: '68px',
    paddingTop: '0px',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingBottom: '8px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '16px',
      marginRight: '16px',
      paddingLeft: '4px',
      paddingRight: '4px',
      paddingBottom: '4px',
    },
  },
  stepText: {
    fontFamily: 'Open Sans,sans-serif',
    fontSize: '14px',
  },
}));

const StepsLoanRequest = ({ stepActive }) => {
  const classes = useStyles();

  const shouldSetActive = (label) => i18n.StepsLoanRequest.indexOf(stepActive) >= i18n.StepsLoanRequest.indexOf(label);

  return (
    <Box className={classes.stepContainer}>
      <Stepper alternativeLabel>
        {i18n.StepsLoanRequest.map((label) => (
          <Step
            key={label}
            completed={false}
            active={shouldSetActive(label)}
            className={classes.stepText}
            sx={{ fontWeight: '700' }}
          >
            <StepLabel>
              {!isMobileOperatingSystem() && (
                <Typography
                  align="center"
                  className={classes.stepText}
                  sx={{ fontWeight: shouldSetActive(label) ? '700' : '400' }}
                >
                  {label}
                </Typography>
              )}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {isMobileOperatingSystem() && (
        <Typography
          align="left"
          className={classes.stepText}
          sx={{ fontWeight: '400', marginTop: '10px' }}
        >
          {stepActive}
        </Typography>
      )}
    </Box>
  );
};

StepsLoanRequest.propTypes = {
  stepActive: PropTypes.string.isRequired,
};

export default StepsLoanRequest;
