import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import TextInputControlled from '../../../components/commons/TextInputControlled';
import i18n from '../../../common/i18n';

const LoanRequestsFilterByLoan = ({ methods, onSubmit }) => (
  <FormProvider {...methods}>
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <Grid container sx={{ marginTop: { xs: '24px', md: '32px' } }}>
        <Grid item xs={12} md={4}>
          <TextInputControlled
            name="loanId"
            label={i18n.LoanRequestsPage.loanInput}
            variant="outlined"
            fullWidth
            mandatory
          />
        </Grid>
      </Grid>
    </form>
  </FormProvider>
);

LoanRequestsFilterByLoan.propTypes = {
  methods: PropTypes.shape().isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default LoanRequestsFilterByLoan;
