import { Outlet } from 'react-router';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import SideMenu from './SideMenu';
import SideMenuMobile from './SideMenuMobile';
import { MENU_WIDTH_SIZE } from '../../../utils/menuUtils';

const DashboardLayoutComponent = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  return (
    <>
      {!isMobile ? (
        <>
          <SideMenu />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              width: `calc(100% - ${MENU_WIDTH_SIZE})`,
              transform: `translate(${MENU_WIDTH_SIZE}, 0px)`,
              paddingBottom: '30px',
            }}
          >
            <Outlet />
          </Box>
        </>
      ) : (
        <>
          <SideMenuMobile />
          <Outlet />
        </>
      )}
    </>
  );
};

export default DashboardLayoutComponent;
