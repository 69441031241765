import PropTypes from 'prop-types';
import { FormProvider } from 'react-hook-form';
import { Grid, Typography } from '@mui/material';
import {
  Button, CardActions, CardContent, Divider,
} from '@doit/pcnt-react-ui-library';
import Card from '../../../components/commons/Card';
import TypeAheadControlled from '../../../components/commons/TypeAheadControlled';
import ComboBoxControlled from '../../../components/commons/ComboBoxControlled';
import TextInputControlled from '../../../components/commons/TextInputControlled';
import i18n from '../../../common/i18n';

const CreateBranchForm = ({
  methods, branchList, onSubmit, loading,
}) => (
  <FormProvider {...methods}>
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <Card sx={{ padding: '32px 24px' }}>
        <CardContent>
          <Typography align="left" sx={{ fontWeight: '700', fontSize: '20px' }}>{i18n.ManageBranchesPage.title}</Typography>
          <Typography align="left" sx={{ fontSize: '14px' }}>{i18n.ManageBranchesPage.description}</Typography>

          <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />

          <Typography align="left" sx={{ fontSize: '16px', fontWeight: '600' }}>{i18n.ManageBranchesPage.sectionTitles[0]}</Typography>

          <Grid container sx={{ marginTop: '32px' }} columnSpacing="24px">
            <Grid item xs="auto">
              <TypeAheadControlled
                name="branch"
                label={i18n.ManageBranchesPage.inputBranch}
                options={branchList}
                disableClearable
                mandatory
              />
            </Grid>
            <Grid item xs="auto">
              <ComboBoxControlled
                name="profile"
                label={i18n.ManageBranchesPage.inputProfile}
                options={i18n.ManageBranchesPage.roles}
              />
            </Grid>
          </Grid>

          <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />

          <Typography align="left" sx={{ fontSize: '16px', fontWeight: '600' }}>{i18n.ManageBranchesPage.sectionTitles[1]}</Typography>

          <Grid container sx={{ marginTop: '8px' }} columnSpacing="24px" rowSpacing="24px">
            <Grid item xs="auto">
              <TextInputControlled
                name="name"
                label={i18n.ManageBranchesPage.inputName}
                variant="outlined"
                fullWidth
                mandatory
              />
            </Grid>
            <Grid item xs="auto">
              <TextInputControlled
                name="surname"
                label={i18n.ManageBranchesPage.inputSurname}
                variant="outlined"
                fullWidth
                mandatory
              />
            </Grid>
            <Grid item xs="auto">
              <TextInputControlled
                name="identifier"
                label={i18n.ManageBranchesPage.inputIdentifier}
                helperText={i18n.ManageBranchesPage.inputIdentifierHint}
                variant="outlined"
                fullWidth
                mandatory
              />
            </Grid>
            <Grid item xs="auto">
              <TextInputControlled
                name="phone"
                label={i18n.ManageBranchesPage.inputPhone}
                helperText={i18n.ManageBranchesPage.inputPhoneHint}
                variant="outlined"
                fullWidth
                mandatory
              />
            </Grid>
            <Grid item xs="auto">
              <TextInputControlled
                name="email"
                label={i18n.ManageBranchesPage.inputEmail}
                variant="outlined"
                fullWidth
                mandatory
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button type="submit" color="grey" loading={loading}>{i18n.ManageBranchesPage.cta}</Button>
        </CardActions>
      </Card>
    </form>
  </FormProvider>
);

CreateBranchForm.propTypes = {
  methods: PropTypes.shape().isRequired,
  branchList: PropTypes.arrayOf(PropTypes.shape()),
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

CreateBranchForm.defaultProps = {
  branchList: [],
  loading: false,
};

export default CreateBranchForm;
