import { useLocation, useParams } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { getLoanRequestWithDocumentData, getRedirectionByMinHash } from '../../api/workflowApiService';
import { isExpired } from '../../api/loanRequestApiService';
import useNavigation from '../useNavigation';
import { useToaster } from '../../contexts/ToasterContext';
import i18n from '../../common/i18n';
import { authenticate } from '../../api/authenticationApiService';
import { useAuth } from '../../contexts/AuthContext';

const useRedirectController = () => {
  const { saveLoggedUser } = useAuth();
  const { hash, minHash } = useParams();
  const location = useLocation();
  const { navigation, goHome, getNextStep } = useNavigation();
  const { showToast } = useToaster();

  const checkExpired = async (loanRequest) => {
    try {
      const { data: expired } = await isExpired(hash);
      if (!expired) {
        const nextStep = getNextStep(loanRequest);
        navigation(`${nextStep}${loanRequest.hashKey}`);
      } else {
        showToast(i18n.RedirectPage.expiredLoan, 'error');
        goHome();
      }
    } catch (error) {
      showToast(i18n.RedirectPage.unknownLoan, 'error');
      goHome();
    }
  };

  const doAuthenticate = async () => {
    const res = await authenticate();
    saveLoggedUser(res.data);
  };

  const goNextStep = async () => {
    try {
      if (location.pathname.startsWith('/r/')) {
        const { data: loanRequest } = await getLoanRequestWithDocumentData(hash);
        checkExpired(loanRequest);
      } else if (location.pathname.startsWith('/m/')) {
        const res = await getRedirectionByMinHash(minHash);
        await doAuthenticate();
        const { data: loanRequest } = await getLoanRequestWithDocumentData(res.data.hashLoanId);
        const nextStep = getNextStep(loanRequest);
        const jsonParams = JSON.parse(res.data.urlParams);
        console.log(`${nextStep}${loanRequest.hashKey}`);
        console.log(createSearchParams({
          directUploadImages: String(jsonParams.directUploadImages),
        }));
        navigation(`${nextStep}${loanRequest.hashKey}`);
      } else {
        goHome();
      }
    } catch (error) {
      showToast(i18n.RedirectPage.notExistLoan, 'error');
      goHome();
    }
  };

  return { goNextStep };
};

export default useRedirectController;
