import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import {
  Box,
  FormControl, Grid, InputLabel, MenuItem, Select, Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Button, Divider, FileUpload } from '@doit/pcnt-react-ui-library';
import StepHeader from './StepHeader';
import i18n from '../../../common/i18n';
import LoanDocumentSendContinueLink from './LoadDocumentSendContinueLink';
import UploadDocumentCard from './UploadDocumentCard';
import useBiometric from '../../../hooks/useBiometric';
import useNavigation from '../../../hooks/useNavigation';
import StepsLoanRequest from './StepsLoanRequest';

const LoadDocumentMobile = ({
  loanRequest, requiredDocuments, onLoadDocument, uploadImageLoading,
  selectedOption, setSelectedOption, documentToUpload, setDocumentToUpload, image, setImage, canContinue,
  sendLinkLoading, onSendLinkToCustomer,
}) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  const location = useLocation();
  const { goToSignature } = useNavigation();
  const { loading: biometricLoading, doOnboarding } = useBiometric();

  const isBiometric = (doc) =>
    doc.name === 'RQFoto' && doc.userInputOptions.inputMethod === '4ID' && !location?.state?.selfieOn;

  const isSelfie = (doc) => doc.name === 'RQFoto';

  const isDisabled = (doc) => {
    let disabled = false;
    if (isBiometric(doc) || isSelfie(doc)) {
      const frontLoaded = requiredDocuments.find((d) => d.name === 'RQDNIfrente' && d.userInputOptions.defaultValue);
      const backLoaded = requiredDocuments.find((d) => d.name === 'RQDNIdorso' && d.userInputOptions.defaultValue);
      disabled = !frontLoaded || !backLoaded;
    }
    return disabled;
  };

  const resolveLoadImageMessageTitle = () => {
    let message = i18n.DocumentUploadPage[documentToUpload.name] || documentToUpload.userInputOptions.label;
    if (image) {
      message = i18n.DocumentUploadPage.imageLoadedTitle;
    }
    return message;
  };

  const resolveLoadImageMessageDescription = () => {
    const message = image ? i18n.DocumentUploadPage.imageLoadedDescription : i18n.DocumentUploadPage.imageToLoadDescription;
    return message;
  };

  return (
    <>
      <StepsLoanRequest stepActive={i18n.StepsLoanRequest[3]} />
      <Grid container sx={{ paddingLeft: '24px', paddingRight: '24px', backgroundColor: '#FFF' }}>
        {/* MUESTRA EL SELECTOR DE FORMAS DE CARGAR LA DOCUMENTACION: ENVIAR LINK O CONTINUAR ACA */}
        {!documentToUpload && (
          <Grid item xs={12}>
            <StepHeader loanRequest={loanRequest} />
            <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
            <Typography align="left" sx={{ fontSize: '20px', fontWeight: 600 }}>{i18n.DocumentUploadPage.title}</Typography>
            <Typography align="left" sx={{ fontSize: '14px', marginTop: '4px', marginBottom: '32px' }}>
              {i18n.DocumentUploadPage.description}
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="opt-select-label">{i18n.DocumentUploadPage.selectOption}</InputLabel>
              <Select
                labelId="opt-select-label"
                id="opt-select"
                value={selectedOption}
                label={i18n.DocumentUploadPage.selectOption}
                onChange={(e) => setSelectedOption(e.target.value)}
              >
                {i18n.DocumentUploadPage.tabOptions.map((option) => (
                  <MenuItem value={option} key={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* MUESTRA LA SECCION PARA CONTINUAR ENVIANDO LINK AL CLIENTE */}
            {selectedOption === i18n.DocumentUploadPage.tabOptions[0] && (
              <LoanDocumentSendContinueLink loanRequest={loanRequest} onSendLink={onSendLinkToCustomer} loading={sendLinkLoading} />
            )}
            {/* MUESTRA LA SECCION CONTINUAR ACA, MUESTRA LAS CAJITAS DE DOCUMENTOS */}
            {selectedOption === i18n.DocumentUploadPage.tabOptions[1] && (
              <>
                <Typography align="left" sx={{ marginTop: '32px', fontSize: '14px' }}>{i18n.DocumentUploadPage.uploadDocumentTitle}</Typography>
                {requiredDocuments.map((document) => (
                  <UploadDocumentCard
                    key={document.name}
                    document={document}
                    onClick={() => setDocumentToUpload(document)}
                    disabled={isDisabled(document)}
                  />
                ))}
                <Button
                  variant="primary"
                  color="grey"
                  onClick={() => goToSignature(loanRequest.hashKey)}
                  fullWidth
                  disabled={!canContinue}
                  sx={{ marginTop: '64px' }}
                >
                  {i18n.DocumentUploadPage.continueCTA}
                </Button>
              </>
            )}
          </Grid>
        )}
        {/* MUESTRA EL INPUT PARA SUBIR EL DOCUMENTO */}
        {documentToUpload && !isBiometric(documentToUpload) && (
          <Grid item xs={12}>
            <Typography align="left" sx={{ fontSize: '20px', fontWeight: 600 }}>{resolveLoadImageMessageTitle()}</Typography>
            <Typography align="left" sx={{ fontSize: '14px' }}>{resolveLoadImageMessageDescription()}</Typography>
            <Box className="upload-document" sx={{ marginTop: '32px' }}>
              <FileUpload
                icon={<img src="/images/upload-icon.svg" alt="upload" width="56px" />}
                buttonLabel={image ? i18n.DocumentUploadPage.retryImageButton : i18n.DocumentUploadPage.uploadImageButton}
                camera={isMobile}
                maxFileSize={1024 * 1024 * 15} // 15 MB
                onFileChange={(images) => setImage(images[0])}
              />
            </Box>
            <Box sx={{ marginTop: '64px' }}>
              {image && (
                <Button
                  variant="contained"
                  color="grey"
                  fullWidth
                  sx={{ marginBottom: '12px' }}
                  onClick={() => onLoadDocument(image)}
                  loading={uploadImageLoading}
                >
                  {i18n.DocumentUploadPage.continueCTA}
                </Button>
              )}
              <Button fullWidth variant="tertiary" color="grey" onClick={() => { setDocumentToUpload(undefined); setImage(undefined); }}>
                {i18n.DocumentUploadPage.backCTA}
              </Button>
            </Box>
          </Grid>
        )}
        {/* CARGA LA FOTO POR BIOMETRIA */}
        {documentToUpload && isBiometric(documentToUpload) && (
          <Grid item xs={12}>
            <img src="/images/selfie-image.svg" alt="selfe" style={{ marginTop: '40px', marginBottom: '48px' }} />
            <Typography align="left" sx={{ fontSize: '16px', fontWeight: 600, marginBottom: '12px' }}>
              {i18n.DocumentUploadPage.biometricTitle}
            </Typography>
            <ul style={{ paddingLeft: '30px' }}>
              {i18n.DocumentUploadPage.biometricTips.map((tip) => (
                <li key={tip}>
                  <Typography align="left" sx={{ fontSize: '14px', marginTop: '8px' }}>{tip}</Typography>
                </li>
              ))}
            </ul>
            <Button
              variant="contained"
              color="grey"
              fullWidth
              sx={{ marginTop: '32px' }}
              onClick={() => doOnboarding(loanRequest.hashKey)}
              loading={biometricLoading}
            >
              {i18n.DocumentUploadPage.biometricCTA}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

LoadDocumentMobile.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
  requiredDocuments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onLoadDocument: PropTypes.func.isRequired,
  uploadImageLoading: PropTypes.bool,
  setDocumentToUpload: PropTypes.func.isRequired,
  documentToUpload: PropTypes.shape(),
  selectedOption: PropTypes.string.isRequired,
  setSelectedOption: PropTypes.func.isRequired,
  image: PropTypes.shape(),
  setImage: PropTypes.func.isRequired,
  canContinue: PropTypes.bool,
  sendLinkLoading: PropTypes.bool,
  onSendLinkToCustomer: PropTypes.func.isRequired,
};

LoadDocumentMobile.defaultProps = {
  uploadImageLoading: false,
  documentToUpload: undefined,
  image: undefined,
  canContinue: false,
  sendLinkLoading: false,
};

export default LoadDocumentMobile;
