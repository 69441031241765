import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { Controller, FormProvider } from 'react-hook-form';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { STATUS_LIST_FILTER } from '../../../constants/loanRequestStatusConstants';
import i18n from '../../../common/i18n';
import { useAuth } from '../../../contexts/AuthContext';
import { ROLE_SALES_MANAGER } from '../../../constants/rolesConstants';
import MultiSelectControlled from '../../../components/commons/MultiSelectControlled';

const LoanRequestsFilters = ({
  methods, onSubmit, branchList, sellerList,
}) => {
  const { hasRole } = useAuth();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container sx={{ marginTop: { xs: '24px', md: '32px' } }} rowGap="24px" columnGap="24px">
          <Grid item xs={12} md={3}>
            <MultiSelectControlled name="status" label={i18n.LoanRequestsPage.statusList} options={STATUS_LIST_FILTER} />
          </Grid>
          <Grid item xs={12} md={3}>
            <MultiSelectControlled name="seller" label={i18n.LoanRequestsPage.sellerList} options={sellerList} />
          </Grid>
          {hasRole(ROLE_SALES_MANAGER) && (
            <Grid item xs={12} md={3}>
              <MultiSelectControlled name="branch" label={i18n.LoanRequestsPage.branchList} options={branchList} />
            </Grid>
          )}
        </Grid>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <Grid container sx={{ marginTop: { xs: '24px', md: '32px' } }} rowGap="24px" columnGap="24px">
            <Grid item xs={12} md={3}>
              <Controller
                name="dateFrom"
                control={methods.control}
                defaultValue={dayjs().subtract(2, 'week')}
                render={({ field }) => (
                  <DesktopDatePicker
                    label={i18n.LoanRequestsPage.dateFrom}
                    value={field.value}
                    onChange={(newValue) => field.onChange(newValue)}
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                name="dateTo"
                control={methods.control}
                defaultValue={dayjs()}
                render={({ field }) => (
                  <DesktopDatePicker
                    label={i18n.LoanRequestsPage.dateTo}
                    value={field.value}
                    onChange={(newValue) => field.onChange(newValue)}
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
      </form>
    </FormProvider>
  );
};

LoanRequestsFilters.propTypes = {
  methods: PropTypes.shape().isRequired,
  onSubmit: PropTypes.func.isRequired,
  branchList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  sellerList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default LoanRequestsFilters;
