import {
  Alert, Button, CardActions, CardContent,
} from '@doit/pcnt-react-ui-library';
import {
  Box, CircularProgress, FormControl, FormControlLabel, FormHelperText, Grid, InputAdornment,
  InputLabel, MenuItem, Radio, RadioGroup, Select, Tooltip, Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { HelpOutline } from '@mui/icons-material';
import Card from '../../components/commons/Card';
import CardHeader from '../../components/commons/CardHeader';
import i18n from '../../common/i18n';
import Divider from '../../components/commons/Divider';
import useQuotationController from '../../hooks/controllers/useQuotationController';
import Loading from '../../components/commons/Loading';
import SectionTitle from '../../components/commons/SectionTitle';
import TextInputControlled from '../../components/commons/TextInputControlled';
import StepsLoanRequest from '../loanRequest/components/StepsLoanRequest';
import { quotationSchemaValidation } from '../../forms/dashboardSchemaValidations';
import { useAuth } from '../../contexts/AuthContext';

const QuotationPage = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const { isInternetChannel } = useAuth();
  const {
    globalLoading,
    productList,
    onSelectProduct,
    subproductList,
    onSelectSubproduct,
    termList,
    resolveCustomerIdentification,
    loadingCustomers,
    customerList,
    requestGender,
    howMeetUsList,
    doSubmit,
  } = useQuotationController();
  const methods = useForm({
    resolver: yupResolver(quotationSchemaValidation(!!howMeetUsList)),
  });

  if (globalLoading) {
    return <Loading />;
  }

  if (isInternetChannel()) {
    return (
      <Alert severity="info" sx={{ margin: '20px' }}>
        {i18n.QuotationPage.quotationUnavailable}
      </Alert>
    );
  }

  return (
    <>
      <StepsLoanRequest stepActive={i18n.StepsLoanRequest[0]} />
      <Card>
        <CardHeader title={i18n.QuotationPage.title} />
        <Divider />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(doSubmit)}>
            {/* SELECCION DE PRODUCTO, SUBPRODUCTO Y PLAZO */}
            <CardContent>
              <SectionTitle>
                {i18n.QuotationPage.productAndSubproductSection}
              </SectionTitle>
              <Grid container sx={{ marginTop: '24px' }} spacing={2}>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth error={!!methods.formState?.errors?.product}>
                    <InputLabel id="product-select-label">{i18n.QuotationPage.productLabel}</InputLabel>
                    <Controller
                      name="product"
                      control={methods.control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="product-select-label"
                          id="product-select"
                          value={field.value || 'none'}
                          label={i18n.QuotationPage.productLabel}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            onSelectProduct(e.target.value, methods);
                          }}
                        >
                          <MenuItem value="none">{i18n.QuotationPage.productEmptyLabel}</MenuItem>
                          {productList.map((product) => (
                            <MenuItem value={product.idProducto} key={product.idProducto}>{product.nombreProducto}</MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {methods.formState.errors.product && (
                      <FormHelperText>{methods.formState?.errors?.product?.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth error={!!methods.formState?.errors?.subproduct}>
                    <InputLabel id="subproduct-select-label">{i18n.QuotationPage.subproductLabel}</InputLabel>
                    <Controller
                      name="subproduct"
                      control={methods.control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="subproduct-select-label"
                          id="subproduct-select"
                          value={field.value || 'none'}
                          label={i18n.QuotationPage.subproductLabel}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            onSelectSubproduct(e.target.value, methods);
                          }}
                        >
                          <MenuItem value="none">{i18n.QuotationPage.subproductEmptyLabel}</MenuItem>
                          {subproductList?.map((subproduct) => (
                            <MenuItem value={subproduct.idSubProducto} key={subproduct.idSubProducto}>
                              {subproduct.nombreSubProducto}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {methods.formState.errors.product && (
                      <FormHelperText>{methods.formState?.errors?.subproduct?.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                {termList && (
                  <Grid item xs={12} md={2} sx={termList?.length > 1 ? { display: 'block' } : { display: 'none' }}>
                    <FormControl fullWidth error={!!methods.formState?.errors?.terms}>
                      <InputLabel id="terms-select-label">{i18n.QuotationPage.termsLabel}</InputLabel>
                      <Controller
                        name="terms"
                        control={methods.control}
                        defaultValue={termList[0]}
                        render={({ field }) => (
                          <Select
                            labelId="terms-select-label"
                            id="terms-select"
                            value={field.value}
                            label={i18n.QuotationPage.termsLabel}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                            }}
                          >
                            {termList.map((terms) => (
                              <MenuItem value={terms} key={terms}>{terms}</MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {methods.formState.errors.terms && (
                        <FormHelperText>{methods.formState?.errors?.terms?.message}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <Divider />
            {/* SELECCION DATOS PERSONALES */}
            <CardContent>
              <SectionTitle>{i18n.QuotationPage.customerDataSection}</SectionTitle>
              <Grid container sx={{ marginTop: '24px' }} spacing={2}>
                <Grid item xs={12} sm={4} md={2}>
                  <TextInputControlled
                    name="customerId"
                    label={i18n.QuotationPage.customerIdLabel}
                    onBlur={(e) => resolveCustomerIdentification(e, methods)}
                    variant="outlined"
                    inputProps={{ maxLength: 9 }}
                    helperText={i18n.QuotationPage.customerIdHint}
                    fullWidth
                    mandatory
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={4}>
                  <FormControl fullWidth error={!!methods.formState?.errors?.customerTaxId}>
                    <InputLabel id="customer-select-label">{i18n.QuotationPage.customerTaxIdLabel}</InputLabel>
                    <Controller
                      name="customerTaxId"
                      control={methods.control}
                      defaultValue={customerList && customerList.length > 0 ? customerList[0].cuit : 'none'}
                      render={({ field }) => (
                        <>
                          <Select
                            {...field}
                            labelId="customer-select-label"
                            id="customer-select"
                            value={field.value}
                            label={i18n.QuotationPage.customerTaxIdLabel}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                            }}
                            startAdornment={
                              loadingCustomers && (
                                <InputAdornment position="start">
                                  <CircularProgress size={20} />
                                </InputAdornment>
                              )
                            }
                          >
                            <MenuItem value="none">{i18n.QuotationPage.customerTaxIdLabel}</MenuItem>
                            {customerList?.map((customer) => (
                              <MenuItem value={customer.cuit} key={customer.cuit}>
                                {`${customer.nombrecompleto} (${customer.cuit})`}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                    />
                    {methods.formState.errors.customerTaxId && (
                      <FormHelperText>{methods.formState?.errors?.customerTaxId?.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                {requestGender && (
                  <Grid item xs={8} md={2}>
                    <FormControl fullWidth error={!!methods.formState?.errors?.gender}>
                      <InputLabel id="gender-select-label">{i18n.QuotationPage.genderLabel}</InputLabel>
                      <Controller
                        name="gender"
                        control={methods.control}
                        defaultValue="M"
                        render={({ field }) => (
                          <Select
                            labelId="gender-select-label"
                            id="gender-select"
                            value={field.value}
                            label={i18n.QuotationPage.genderLabel}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                            }}
                          >
                            <MenuItem value="M">M</MenuItem>
                            <MenuItem value="F">F</MenuItem>
                          </Select>
                        )}
                      />
                      {methods.formState.errors.customerTaxId && (
                        <FormHelperText>{methods.formState?.errors?.gender?.message}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                )}
                {howMeetUsList && (
                  <Grid item xs={8} md={2}>
                    <FormControl fullWidth error={!!methods.formState?.errors?.howMeetUs}>
                      <InputLabel id="how-meet-us-select-label">{i18n.QuotationPage.howMeetUsLabel}</InputLabel>
                      <Controller
                        name="howMeetUs"
                        control={methods.control}
                        defaultValue="none"
                        render={({ field }) => (
                          <Select
                            labelId="how-meet-us-select-label"
                            id="how-meet-us-select"
                            value={field.value}
                            label={i18n.QuotationPage.howMeetUsLabel}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                            }}
                          >
                            <MenuItem value="none">{i18n.QuotationPage.howMeetUsLabel}</MenuItem>
                            {howMeetUsList?.map((reason) => (
                              <MenuItem value={reason} key={reason}>
                                {reason}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {methods.formState.errors.customerTaxId && (
                        <FormHelperText>{methods.formState?.errors?.howMeetUs?.message}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <Divider />
            {/* PEP o SO */}
            <CardContent>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Box mb={1} display="flex" alignItems="center">
                    <Typography align="left">
                      {i18n.QuotationPage.pepLabel}
                    </Typography>
                    <Tooltip title={i18n.QuotationPage.pepHelp}>
                      <HelpOutline sx={{ marginLeft: '10px', color: '#424242' }} />
                    </Tooltip>
                  </Box>
                  <FormControl fullWidth>
                    <Controller
                      name="pep"
                      control={methods.control}
                      defaultValue="false"
                      render={({ field }) => (
                        <RadioGroup {...field} row>
                          <FormControlLabel value="true" control={<Radio />} label="Si" />
                          <FormControlLabel value="false" control={<Radio />} label="No" />
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box mb={1} display="flex" alignItems="center">
                    <Typography align="left">
                      {i18n.QuotationPage.soLabel}
                    </Typography>
                    <Tooltip title={i18n.QuotationPage.soHelp}>
                      <HelpOutline sx={{ marginLeft: '10px', color: '#424242' }} />
                    </Tooltip>
                  </Box>
                  <FormControl fullWidth>
                    <Controller
                      name="so"
                      control={methods.control}
                      defaultValue="false"
                      render={({ field }) => (
                        <RadioGroup {...field} row>
                          <FormControlLabel value="true" control={<Radio />} label="Si" />
                          <FormControlLabel value="false" control={<Radio />} label="No" />
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions alignContent={isMobile ? 'center' : 'right'}>
              <Button type="submit" color="grey">{i18n.QuotationPage.cta}</Button>
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default QuotationPage;
