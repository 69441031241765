import PropTypes from 'prop-types';
import {
  Box, Chip, Grid, Paper, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Typography,
} from '@mui/material';
import { Button, Checkbox } from '@doit/pcnt-react-ui-library';
import { makeStyles } from '@mui/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import i18n from '../../../common/i18n';
import LoanRequestRow from './LoanRequestRow';
import { ReactComponent as SendIcon } from '../../../assets/images/send-icon.svg';
import Loading from '../../../components/commons/Loading';
import { trimString } from '../../../utils/functionsUtils';

const useStyles = makeStyles(() => ({
  icon: {
    '& path': {
      stroke: '#424242',
      fill: '#424242',
    },
  },
  iconDisabled: {
    '& path': {
      stroke: '#bdbdbd',
      fill: '#bdbdbd',
    },
  },
}));

const LoanRequestsList = ({
  loanRequestList, filters, onSelectAll, onSelect, onSendOffer, onClearFilters, filterLoading,
  onContinue, onSendWhatsapp, onDownloadDocs, onPaymentVoucher, onAccountStatus, onSendPaymentVoucher, onSendOfferLoan, onDistanceSelling,
}) => {
  const classes = useStyles();

  const isDisabled = () => !loanRequestList.find((lr) => lr.isSelected);

  if (filterLoading) {
    return <Loading />;
  }

  //  NO TIENE FILTROS
  if (!filters) {
    return (
      <>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: '24px' }}>
          <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>{i18n.LoanRequestsPage.noFilter[0]}</Typography>
          <Typography sx={{ fontSize: '16px', marginTop: '12px' }}>{i18n.LoanRequestsPage.noFilter[1]}</Typography>
        </Box>
      </>
    );
  }

  // BUSQUEDA SIN RESULTADOS
  if (!loanRequestList || loanRequestList.length === 0) {
    return (
      <>
        <Box sx={{ backgroundColor: '#f5f5f5', padding: '24px' }}>
          <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>{i18n.LoanRequestsPage.noResult[0]}</Typography>
          <Typography sx={{ fontSize: '16px', marginTop: '12px' }}>{i18n.LoanRequestsPage.noResult[1]}</Typography>
        </Box>
      </>
    );
  }

  // LISTADO DE SOLICITUDES
  return (
    <>
      <Grid container sx={{ marginBottom: '24px' }} columnGap="8px" rowGap="8px" alignItems="center">
        <Grid item xs="auto">
          <Typography sx={{ fontSize: '12px' }}>{i18n.LoanRequestsPage.filters.description}</Typography>
        </Grid>
        {Object.entries(filters).map(([key, value]) => (
          <Grid item xs="auto" key={key}>
            <Chip label={trimString(`${i18n.LoanRequestsPage.filters[key]}: ${value}`, 26, true)} />
          </Grid>
        ))}
        <Grid item xs="auto">
          <Button variant="tertiary" color="grey" onClick={onClearFilters} endIcon={<DeleteOutlineIcon />}>
            {i18n.LoanRequestsPage.filters.delete}
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ marginBottom: '16px', textAlign: 'left' }}>
        <Button
          variant="secondary"
          color="grey"
          endIcon={<SendIcon className={isDisabled() ? classes.iconDisabled : classes.icon} />}
          onClick={onSendOffer}
          disabled={isDisabled()}
        >
          {i18n.LoanRequestsPage.sendOfferCTA}
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                <Checkbox onChange={onSelectAll} />
              </TableCell>
              {i18n.LoanRequestsPage.tableHeaders.map((header) => (
                <TableCell key={header}>
                  <Typography sx={{ fontWeight: '600', fontSize: '14px', whiteSpace: 'nowrap' }}>{header}</Typography>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {loanRequestList.map((loanRequest, index) => (
              <LoanRequestRow
                key={loanRequest.sappLoanId}
                loanRequest={loanRequest}
                index={index}
                onSelect={onSelect}
                onContinue={onContinue}
                onSendWhatsapp={onSendWhatsapp}
                onDownloadDocs={onDownloadDocs}
                onPaymentVoucher={onPaymentVoucher}
                onAccountStatus={onAccountStatus}
                onSendPaymentVoucher={onSendPaymentVoucher}
                onSendOffer={onSendOfferLoan}
                onDistanceSelling={onDistanceSelling}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

LoanRequestsList.propTypes = {
  loanRequestList: PropTypes.arrayOf(PropTypes.shape()),
  filters: PropTypes.shape(),
  onSelectAll: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSendOffer: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func.isRequired,
  filterLoading: PropTypes.bool,
  onContinue: PropTypes.func.isRequired,
  onSendWhatsapp: PropTypes.func.isRequired,
  onDownloadDocs: PropTypes.func.isRequired,
  onPaymentVoucher: PropTypes.func.isRequired,
  onAccountStatus: PropTypes.func.isRequired,
  onSendPaymentVoucher: PropTypes.func.isRequired,
  onSendOfferLoan: PropTypes.func.isRequired,
  onDistanceSelling: PropTypes.func.isRequired,
};

LoanRequestsList.defaultProps = {
  loanRequestList: undefined,
  filters: undefined,
  filterLoading: false,
};

export default LoanRequestsList;
