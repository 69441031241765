import { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {
  Box, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button } from '@doit/pcnt-react-ui-library';
import i18n from '../../../common/i18n';
import TextInputControlled from '../../../components/commons/TextInputControlled';
import { moneyFormatter } from '../../../utils/formatterUtils';
import SettlementsCancelDialog from './SettlementsCancelDialog';

const SettledLoans = ({
  loadingSearch, loadingDownload, onSearch, loanList, onDownload,
  onCancel, loadingCancel, canceledOk, setCanceledOk,
}) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const methods = useForm();
  const [selectedLoan, setSelectedLoan] = useState();
  const [open, setOpen] = useState(false);

  const [page, setPage] = useState(0);
  const rowsPerPage = 10;

  const handleCancel = (loan) => {
    setSelectedLoan(loan);
    setOpen(true);
  };

  const search = (data) => {
    setPage(0);
    onSearch(data);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(search)}>
            <Grid container>
              <Grid item xs={12} md={3} sx={{ paddingRight: { xs: '', md: '24px' } }}>
                <Controller
                  name="dateFrom"
                  control={methods.control}
                  defaultValue={dayjs().startOf('month')}
                  render={({ field }) => (
                    <DesktopDatePicker
                      label={i18n.SettlementsPage.dateFrom}
                      value={field.value}
                      onChange={(newValue) => field.onChange(newValue)}
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3} sx={{ paddingRight: { xs: '', md: '24px' }, marginTop: { xs: '24px', md: '0' } }}>
                <Controller
                  name="dateTo"
                  control={methods.control}
                  defaultValue={dayjs()}
                  render={({ field }) => (
                    <DesktopDatePicker
                      label={i18n.SettlementsPage.dateTo}
                      value={field.value}
                      onChange={(newValue) => field.onChange(newValue)}
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3} sx={{ paddingRight: { xs: '', md: '12px' }, marginTop: { xs: '24px', md: '0' } }}>
                <TextInputControlled
                  name="dni"
                  label={i18n.SettlementsPage.dniInput}
                  variant="outlined"
                  fullWidth
                  mandatory
                />
              </Grid>
              <Grid item xs={12} md={3} sx={{ paddingLeft: { xs: '', md: '12px' }, marginTop: { xs: '24px', md: '0' } }}>
                <TextInputControlled
                  name="loanId"
                  label={i18n.SettlementsPage.loanInput}
                  variant="outlined"
                  fullWidth
                  mandatory
                />
              </Grid>
            </Grid>
            <Box sx={{ textAlign: 'right', marginTop: '52px' }}>
              {!isMobile && (
                <Button
                  variant="secondary"
                  color="grey"
                  onClick={methods.handleSubmit(onDownload)}
                  loading={loadingDownload}
                  fullWidth={isMobile}
                  sx={{ marginBottom: { xs: '24px', md: '0px' } }}
                >
                  {i18n.SettlementsPage.downloanCTA}
                </Button>
              )}
              <Button
                variant="primary"
                color="grey"
                type="submit"
                loading={loadingSearch}
                fullWidth={isMobile}
                sx={{ marginLeft: { xs: '', md: '24px' }, marginBottom: { xs: '12px', md: '0px' } }}
              >
                {i18n.SettlementsPage.searchCTA}
              </Button>
              {isMobile && (
                <Button
                  variant="secondary"
                  color="grey"
                  onClick={methods.handleSubmit(onDownload)}
                  loading={loadingDownload}
                  fullWidth={isMobile}
                  sx={{ marginBottom: { xs: '24px', md: '0px' } }}
                >
                  {i18n.SettlementsPage.downloanCTA}
                </Button>
              )}
            </Box>
          </form>
        </FormProvider>
      </LocalizationProvider>
      {loanList && loanList.length > 0 && (
        <>
          <TableContainer component={Paper} sx={{ marginTop: '32px' }}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  {i18n.SettlementsPage.loanListTableHeaders.map((header) => (
                    <TableCell key={header}>
                      <Typography sx={{ fontWeight: '600', fontSize: '14px', whiteSpace: 'nowrap' }}>{header}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loanList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((loan, index) => (
                  <TableRow key={loan.sappLoanId} sx={{ backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f5' }}>
                    <TableCell><Typography sx={{ fontSize: '14px' }}>{loan.customerId}</Typography></TableCell>
                    <TableCell><Typography sx={{ fontSize: '14px' }}>{loan.sappLoanId}</Typography></TableCell>
                    <TableCell><Typography sx={{ fontSize: '14px' }}>{loan.status}</Typography></TableCell>
                    <TableCell><Typography sx={{ fontSize: '14px' }}>{loan.customerName}</Typography></TableCell>
                    <TableCell><Typography sx={{ fontSize: '14px' }}>{dayjs(loan.liqDate).format('DD/MM/YYYY')}</Typography></TableCell>
                    <TableCell><Typography sx={{ fontSize: '14px' }}>{dayjs(loan.actDate).format('DD/MM/YYYY')}</Typography></TableCell>
                    <TableCell><Typography sx={{ fontSize: '14px' }}>{moneyFormatter(loan.requestedAmount)}</Typography></TableCell>
                    <TableCell><Typography sx={{ fontSize: '14px' }}>{loan.paymentMethod}</Typography></TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleCancel(loan)}><CancelOutlinedIcon fontSize="small" sx={{ color: '#D0021B' }} /></IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ textAlign: 'right', marginTop: '18px' }}>
            <Button
              variant="tertiary"
              onClick={() => setPage((prev) => Math.max(prev - 1, 0))}
              disabled={page === 0}
              className="minimalist"
              color="grey"
              startIcon={<ArrowBackIosIcon />}
            >
              {i18n.SettlementsPage.prevPage}
            </Button>
            <Button
              variant="tertiary"
              onClick={() => setPage((prev) => Math.min(prev + 1, Math.ceil(loanList.length / rowsPerPage) - 1))}
              disabled={page >= Math.ceil(loanList.length / rowsPerPage) - 1}
              className="minimalist"
              color="grey"
              endIcon={<ArrowForwardIosIcon />}
            >
              {i18n.SettlementsPage.nextPage}
            </Button>
          </Box>
        </>
      )}
      {selectedLoan && (
        <SettlementsCancelDialog
          loan={selectedLoan}
          open={open}
          onClose={() => { setOpen(false); setCanceledOk(false); }}
          onConfirm={() => onCancel(selectedLoan)}
          loading={loadingCancel}
          canceledOk={canceledOk}
        />
      )}
    </>
  );
};

SettledLoans.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  loadingSearch: PropTypes.bool,
  loadingDownload: PropTypes.bool,
  loanList: PropTypes.arrayOf(PropTypes.shape()),
  onCancel: PropTypes.func.isRequired,
  loadingCancel: PropTypes.bool,
  canceledOk: PropTypes.bool,
  setCanceledOk: PropTypes.func.isRequired,
};

SettledLoans.defaultProps = {
  loadingSearch: false,
  loadingDownload: false,
  loanList: [],
  loadingCancel: false,
  canceledOk: false,
};

export default SettledLoans;
