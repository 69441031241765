import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  getDocuments, getInstallmentsApi, getInstallmentsWithOriginalLine, getLoanRequest, getRequiredInputFields, getVehicleParameterMinAmount,
  sendVoucher,
  setInstallment,
} from '../../api/workflowApiService';
import { PLLLA_DEBIT_CARD_FIELD_NAME, PTLLA_DEBIT_CARD } from '../../constants/commerceContants';
import i18n from '../../common/i18n';
import { getApiErrorMessage } from '../../services/errorApiService';
import { useToaster } from '../../contexts/ToasterContext';
import { getAvailableAmount, hasAdvancePayment, isProductToPayVehicle } from '../../utils/loanRequestUtils';
import { getVehicleBrands, getVehicleModels, getVehiclePrice } from '../../api/vehicleApiService';
import useNavigation from '../useNavigation';

const useInstallmentPlanController = () => {
  const { showToast } = useToaster();
  const { hash } = useParams();

  const { verifyHasDistanceSellingLink, goToCustomerData } = useNavigation();

  const [globalLoading, setGlobalLoading] = useState(false);
  const [loanRequest, setLoanRequest] = useState();
  const [selectedInstallment, setSelectedInstallment] = useState();
  const [brandList, setBrandList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [brand, setBrand] = useState();
  const [model, setModel] = useState();
  const [vehicleModelLoading, setVehicleModelLoading] = useState();
  const [maximumLoanable, setMaximumLoanable] = useState();
  const [sendOfferLoading, setSendOfferLoading] = useState(false);
  const [installmentsLoading, setInstallmentsLoading] = useState(false);
  const [installments, setInstallments] = useState();
  const [requestedAmount, setRequestedAmount] = useState();
  const [advanceAmount, setAdvanceAmount] = useState();
  const [selectInstallmentLoading, setSelectInstallmentLoading] = useState();

  let vehicleMinAmount;

  const getInstallments = async ({ amount }) => {
    try {
      if (amount > getAvailableAmount(loanRequest)) {
        showToast(i18n.InstallmentPlanPage.inputAmountGreaterThanMax, 'info');
        return;
      }

      setInstallmentsLoading(true);
      let amountAsked = amount;
      if (hasAdvancePayment(loanRequest)) {
        // amountAsked: es el calculado luego de aplicar el porcentaje de anticipo, es lo que se va a financiar
        // eslint-disable-next-line operator-assignment
        amountAsked = amountAsked / (1 + (loanRequest.advancePaymentPercentage / 100));
        // advancePaymentAmount: es el monto del anticipo propiamente dicho
        const advancePaymentAmount = amount - amountAsked;
        setAdvanceAmount(advancePaymentAmount);
      }
      setRequestedAmount(amountAsked);
      const { data: installmentsAux } = await getInstallmentsApi(hash, amountAsked);
      const { data: installmentsWithOriginalLineAux } = await getInstallmentsWithOriginalLine(hash, amountAsked, installmentsAux[0].idLinea);
      // Almaceno en las cuotas el monto original para saber si tiene o no descuento
      installmentsWithOriginalLineAux.forEach((iol) => {
        const inst = installmentsAux.find((i) => i.installments === iol.installments);
        inst.amountOriginalLine = iol.amount;
      });
      setInstallments(installmentsAux);
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    } finally {
      setInstallmentsLoading(false);
    }
  };

  const getModelsByBrand = async (brandId) => {
    try {
      setVehicleModelLoading(true);
      const { data: modelListAux } = await getVehicleModels(brandId);
      setModelList(modelListAux);
    } catch (error) {
      showToast(i18n.InstallmentPlanPage.vehicleSelection.modelListError, 'error');
    } finally {
      setVehicleModelLoading(false);
    }
  };

  const onChangeBrand = (e) => {
    const brandId = e.target.value;
    setBrand(brandId);
    setModel('none');
    setMaximumLoanable(undefined);
    getModelsByBrand(brandId);
  };

  const calculateMaximumLoanable = (vehiclePrice) => {
    let availableAmount = loanRequest.response.maxAmount;
    const vehicleMaxLoanable = (vehiclePrice * loanRequest.loanToValue) / 100;
    if (loanRequest.response.prePaidLoan) {
      availableAmount -= loanRequest.response.prePaidLoanAmount;
    }

    let maxLoanable = 0;
    if (availableAmount < vehicleMaxLoanable) {
      maxLoanable = availableAmount;
    } else {
      maxLoanable = vehicleMaxLoanable;
    }

    if (maxLoanable <= 0) {
      showToast(i18n.InstallmentPlanPage.vehicleSelection.priceLowerThanMin, 'info');
    } else {
      setMaximumLoanable(maxLoanable);
    }
  };

  const getVehiclePriceByModelId = async (modelId) => {
    try {
      const res = await getVehiclePrice(modelId);
      if (res.data.list_price) {
        const vehiclePrice = (res.data.list_price * loanRequest.loanToValue) / 100;
        if (vehiclePrice < vehicleMinAmount) {
          showToast(i18n.InstallmentPlanPage.vehicleSelection.priceLowerThanMin, 'info');
        } else {
          calculateMaximumLoanable(res.data.list_price);
        }
      }
    } catch (error) {
      showToast(i18n.InstallmentPlanPage.vehicleSelection.priceError, 'error');
    }
  };

  const onChangeModel = (e) => {
    const modelId = e.target.value;
    setModel(modelId);
    getVehiclePriceByModelId(modelId);
  };

  const sendOffer = async ({ cellphone }) => {
    try {
      setSendOfferLoading(true);
      await sendVoucher(hash, cellphone);
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    } finally {
      setSendOfferLoading(false);
    }
  };

  const choseInstallment = async () => {
    try {
      setSelectInstallmentLoading(true);
      const installment = selectedInstallment;
      installment.requestedAmount = requestedAmount;
      await setInstallment(hash, installment);
      goToCustomerData(hash);
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    } finally {
      setSelectInstallmentLoading(false);
    }
  };

  const init = async () => {
    try {
      setGlobalLoading(true);
      const { data: lr } = await getLoanRequest(hash);

      // Verifica si tiene generado link de venta a distancia
      verifyHasDistanceSellingLink(lr);

      if (!lr.response.rejected) {
        if (isProductToPayVehicle(lr)) {
          const { data: minAmountForVehicle } = await getVehicleParameterMinAmount();
          vehicleMinAmount = minAmountForVehicle;
          try {
            const { data: brandListAux } = await getVehicleBrands();
            setBrandList(brandListAux);
          } catch (error) {
            showToast(i18n.InstallmentPlanPage.vehicleSelection.brandListError, 'error');
          }
        }

        const [{ data: requiredDocuments }, { data: requiredInputFields }] = await Promise.all([getDocuments(hash), getRequiredInputFields(hash)]);

        const cardInput = requiredInputFields.find((field) => field.name === PTLLA_DEBIT_CARD);
        if (cardInput) {
          cardInput.userInputOptions.label = i18n.InstallmentPlanPage.debitCard;
          cardInput.name = PLLLA_DEBIT_CARD_FIELD_NAME;
          requiredDocuments.push(cardInput);
        }
        lr.requiredDocuments = requiredDocuments;
      }

      setLoanRequest(lr);
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    } finally {
      setGlobalLoading(false);
    }
  };

  const doReload = () => {
    init();
  };

  useEffect(() => { init(); }, []);

  return {
    globalLoading,
    loanRequest,
    doReload,
    installments,
    selectedInstallment,
    setSelectedInstallment,
    brandList,
    modelList,
    brand,
    model,
    onChangeBrand,
    vehicleModelLoading,
    onChangeModel,
    maximumLoanable,
    sendOffer,
    sendOfferLoading,
    getInstallments,
    installmentsLoading,
    requestedAmount,
    advanceAmount,
    choseInstallment,
    selectInstallmentLoading,
  };
};

export default useInstallmentPlanController;
