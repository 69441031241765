import PropTypes from 'prop-types';
import { Alert } from '@doit/pcnt-react-ui-library';
import {
  Grid, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import i18n from '../../../common/i18n';
import { REQUIRED_DOCUMENTS } from '../../../constants/imageConstants';

const InstallmentPlanRequiredDocuments = ({ loanRequest }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  return (
    <>
      {isMobile ? (
        <Alert
          severity="info"
          title={i18n.InstallmentPlanPage.requiredDocumentation}
          sx={{ marginTop: '32px', width: 'fit-content', textAlign: 'left' }}
        >
          {loanRequest.requiredDocuments.map((doc, index) => (
            <Typography sx={{ fontSize: '0.875rem', display: 'inline' }} key={doc.name}>
              {doc.userInputOptions.label}
              {index < loanRequest.requiredDocuments.length - 1 && ', '}
            </Typography>
          ))}
        </Alert>
      ) : (
        <Alert severity="info" title={i18n.InstallmentPlanPage.requiredDocumentation} sx={{ marginTop: '32px', width: 'fit-content' }}>
          <Grid container columnSpacing={2}>
            {loanRequest.requiredDocuments.map((doc) => (
              <Grid item xs="auto" display="flex" alignItems="center" key={doc.name}>
                <img src={REQUIRED_DOCUMENTS[doc.name]} alt={doc.userInputOptions.label} />
                <Typography marginLeft="4px" sx={{ fontSize: '0.875rem' }}>
                  {doc.userInputOptions.label}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Alert>
      )}
    </>
  );
};

InstallmentPlanRequiredDocuments.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
};

export default InstallmentPlanRequiredDocuments;
