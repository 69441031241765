import { fetch } from './axiosInstance';

const BASE_URL = '/commerce-affiliation-user-requests';

export const createBranchUser = (user) =>
  fetch({
    url: BASE_URL,
    method: 'post',
    data: user,
  });

export const updateBranchUser = (user, id) =>
  fetch({
    url: `${BASE_URL}/${id}`,
    method: 'put',
    data: user,
  });

export const getBranchUserList = () =>
  fetch({
    url: BASE_URL,
    method: 'get',
  });

export const deleteBranchUser = (id) =>
  fetch({
    url: `${BASE_URL}/${id}`,
    method: 'delete',
  });
