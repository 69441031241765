import { useEffect, useState } from 'react';
import { useCopyToClipboard } from '@uidotdev/usehooks';
import { getLoanRequest } from '../../api/workflowApiService';
import { useToaster } from '../../contexts/ToasterContext';
import { getApiErrorMessage } from '../../services/errorApiService';
import { STATUS_PEN } from '../../constants/loanRequestStatusConstants';
import i18n from '../../common/i18n';
import { ERROR_REQUESTED_AMOUNT_IS_GREATER_THAN_MAX_OFFER } from '../../constants/apiErrorCodeConstants';
import { cleanPaymentLink, generatePaymentLink, generateShowcaseSms } from '../../api/loanRequestApiService';
import { getEnv, ONBOARDING_WT_URL } from '../../api/env';

const useDistanceSellingController = (loanRequest) => {
  const { showToast } = useToaster();

  const [, copyToClipboard] = useCopyToClipboard();

  const [initLoading, setInitLoading] = useState();
  const [loading, setLoading] = useState();
  const [paymentLinkData, setPaymentLinkData] = useState();
  const [editable, setEditable] = useState(true);
  const [link, setLink] = useState(false);

  const init = async () => {
    try {
      setInitLoading(true);
      const { data: lr } = await getLoanRequest(loanRequest.hash);
      setPaymentLinkData({
        productAmount: lr.productToPay?.product?.productAttributes?.productAmount,
        productDescription: lr.productToPay?.product?.productAttributes?.productDescription,
      });
      const allowUpdate = loanRequest.status === STATUS_PEN;
      setEditable(allowUpdate);
      if (lr.productToPay?.product?.productAttributes?.productAmount > 0) {
        setLink(`${getEnv(ONBOARDING_WT_URL)}/m/${loanRequest.hash}`);
      }
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    } finally {
      setInitLoading(false);
    }
  };

  useEffect(() => { init(); }, []);

  const generateLink = async (data) => {
    try {
      setLoading(true);
      const dto = {
        hash: loanRequest.hash,
        productAmount: data.amount,
        productDescription: data.description,
      };
      await generatePaymentLink(dto);
      setPaymentLinkData(dto);
      setLink(`${getEnv(ONBOARDING_WT_URL)}/m/${loanRequest.hash}`);
    } catch (error) {
      if (error?.data?.errorCode === ERROR_REQUESTED_AMOUNT_IS_GREATER_THAN_MAX_OFFER) {
        showToast(i18n.DistanceSelling.amountError, 'error');
      } else {
        showToast(getApiErrorMessage(error), 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const sendLink = async () => {
    try {
      const dto = {
        hash: loanRequest.hash,
        productAmount: paymentLinkData.productAmount,
        productDescription: paymentLinkData.productDescription,
        urlGenerated: link,
      };
      await generateShowcaseSms(dto);
      showToast(i18n.DistanceSelling.linkSent, 'success');
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    }
  };

  const copyLink = () => {
    copyToClipboard(link);
    showToast(i18n.DistanceSelling.linkCopied, 'success');
  };

  const editLink = async () => {
    try {
      const dto = {
        hash: loanRequest.hash,
        productAmount: paymentLinkData.productAmount,
        productDescription: paymentLinkData.productDescription,
        urlGenerated: link,
      };
      await cleanPaymentLink(dto);
      setPaymentLinkData(undefined);
      setLink(undefined);
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    }
  };

  return {
    initLoading,
    loading,
    paymentLinkData,
    generateLink,
    link,
    sendLink,
    copyLink,
    editLink,
    editable,
  };
};

export default useDistanceSellingController;
