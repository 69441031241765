import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { dniSchemaValidation, loanSchemaValidation } from '../../forms/loanRequestsSchemaValidations';
import { useToaster } from '../../contexts/ToasterContext';
import i18n from '../../common/i18n';
import {
  generatePaymentVoucherApi,
  getAccountStatusDocument,
  getBranches, getLoanRequests, getSellerList,
  openPdfDocumentInNewTab,
  sendReportCampaign,
  sendSMSWithPaymentVoucherURL,
} from '../../api/loanRequestApiService';
import { getApiErrorMessage } from '../../services/errorApiService';
import { STATUS_DESCRIPTION, STATUS_LIST_FILTER, STATUS_PEN } from '../../constants/loanRequestStatusConstants';

const useLoanRequestsController = () => {
  const dniForm = useForm({ resolver: yupResolver(dniSchemaValidation) });
  const loanForm = useForm({ resolver: yupResolver(loanSchemaValidation) });
  const filterForm = useForm();

  const { showToast } = useToaster();

  const [globalLoading, setGlobalLoading] = useState(true);
  const [filterLoading, setFilterLoading] = useState(false);
  const [branchList, setBranchList] = useState();
  const [sellerList, setSellerList] = useState();

  // Filtro a mostrar en los chips
  const [selectedFilters, setSelectedFilters] = useState();

  const [loanRequestList, setLoanRequestList] = useState();

  // Lista de creditos para enviar oferta
  const [loansOffer, setLoansOffer] = useState();
  const [openModalOffer, setOpenModalOffer] = useState(false);

  const doFilter = async (filters) => {
    try {
      setFilterLoading(true);
      const { data: loanRequestListAux } = await getLoanRequests(filters);
      setLoanRequestList(loanRequestListAux);
    } catch (error) {
      showToast(i18n.LoanRequestsPage.filterError, 'error');
    } finally {
      setFilterLoading(false);
    }
  };

  const doFilterByDNI = ({ dni }) => {
    const filters = {
      doc: dni,
      dateFrom: new Date(dayjs().subtract(10, 'year')),
      dateTo: new Date(dayjs()),
    };
    // Filtro para los chips
    setSelectedFilters({ doc: dni });
    doFilter(filters);
  };

  const doFilterByLoanId = async ({ loanId }) => {
    const filters = {
      idLoan: loanId,
      dateFrom: new Date(dayjs().subtract(10, 'year')),
      dateTo: new Date(dayjs()),
    };
    // Filtro para los chips
    setSelectedFilters({ idLoan: loanId });
    doFilter(filters);
  };

  const doFilterByFields = async (data) => {
    const statusList = data.status.length > 0 ? data.status : STATUS_LIST_FILTER.map((status) => status.id);
    const filters = {
      status: statusList,
      dateFrom: new Date(data.dateFrom),
      dateTo: new Date(data.dateTo),
      branchIdList: data.branch,
      sellerList: data.seller,
    };
    // Filtro para los chips
    const filtersToShow = {};
    if (statusList.length !== STATUS_LIST_FILTER.length) {
      filtersToShow.status = statusList.map((status) => STATUS_DESCRIPTION[status]);
    }
    filtersToShow.dateFrom = dayjs(data.dateFrom).format('DD/MM/YYYY');
    filtersToShow.dateTo = dayjs(data.dateTo).format('DD/MM/YYYY');
    if (data.branch.length > 0) {
      filtersToShow.branchIdList = data.branch.map((branch) => branchList.find((b) => b.id === branch).label);
    }
    if (data.seller.length > 0) {
      filtersToShow.sellerList = data.seller.map((seller) => sellerList.find((s) => s.id === seller).label);
    }
    setSelectedFilters(filtersToShow);
    doFilter(filters);
  };

  const filterFormMap = [
    { id: '0', form: dniForm, submit: doFilterByDNI },
    { id: '1', form: loanForm, submit: doFilterByLoanId },
    { id: '2', form: filterForm, submit: doFilterByFields },
  ];

  const getBranchList = async () => {
    try {
      const { data: branchListAux } = await getBranches();
      setBranchList(branchListAux.map((branch) => ({ id: branch.id, label: branch.description })));
    } catch (error) {
      showToast(i18n.LoanRequestsPage.branchListError, 'error');
    }
  };

  const getSellersList = async () => {
    try {
      const { data: sellerListAux } = await getSellerList();
      setSellerList(sellerListAux.map((seller) => ({ id: seller.id, label: `${seller.name} ${seller.surname}` })));
    } catch (error) {
      showToast(i18n.LoanRequestsPage.sellerListError, 'error');
    }
  };

  // Cuando hace click en el checkbox del header para seleccionar o deseleccionar todas
  const doSelectAll = (event) => {
    const lrAux = [...loanRequestList];
    lrAux.forEach((lr, index) => {
      if (lr.status === STATUS_PEN) {
        lrAux[index].isSelected = event.target.checked;
      }
    });
    setLoanRequestList(lrAux);
  };

  // Cuando hace click en el checkbox de una solicitud en particular
  const doSelect = (event, loanRequest) => {
    const lrAux = [...loanRequestList];
    lrAux.forEach((lr, index) => {
      if (lr.hash === loanRequest.hash) {
        lrAux[index].isSelected = event.target.checked;
      }
    });
    setLoanRequestList(lrAux);
  };

  const sendOfferModalShow = () => {
    setLoansOffer(loanRequestList.filter((lr) => lr.isSelected));
    setOpenModalOffer(true);
  };

  const sendOfferLoanModalShow = (loanRequest) => {
    setLoansOffer([loanRequest]);
    setOpenModalOffer(true);
  };

  const clearFilters = () => {
    setSelectedFilters(undefined);
    setLoanRequestList(undefined);
  };

  const sendWhatsapp = (loanRequest) => {
    window.open(`http://api.whatsapp.com/send?phone=549${loanRequest.cellNumber}&text=Hola, te contacto de ${loanRequest.commerceName}`, '_blank');
  };

  const downloadDocs = (loanRequest) => {
    openPdfDocumentInNewTab(loanRequest.hash);
  };

  const generatePaymentVoucher = async (loanRequest) => {
    try {
      const { data } = await generatePaymentVoucherApi(loanRequest.hash);
      window.open(data, '_blank');
    } catch (error) {
      showToast(i18n.LoanRequestsPage.paymentVoucherError, 'error');
    }
  };

  const getAccountStatus = async (loanRequest) => {
    try {
      await getAccountStatusDocument(loanRequest.hash);
    } catch (error) {
      showToast(i18n.LoanRequestsPage.accountStatusError, 'error');
    }
  };

  const sendPaymentVoucher = async (loanRequest) => {
    try {
      await sendSMSWithPaymentVoucherURL(loanRequest.hash);
      showToast(i18n.LoanRequestsPage.paymentVoucherSent, 'info');
    } catch (error) {
      showToast(getApiErrorMessage(error), 'info');
    }
  };

  const sendOffer = async () => {
    try {
      // Cierro el modal
      setOpenModalOffer(false);
      await sendReportCampaign(loansOffer);
      const message = loansOffer.length > 1 ? i18n.LoanRequestSendOfferModal.sent[1] : i18n.LoanRequestSendOfferModal.sent[0];
      showToast(message, 'success');
      // Limpio el listado de ofertas
      setLoansOffer(undefined);
      // Desmarco las solicitudes seleccionadas
      const list = loanRequestList.map((lr) => ({ ...lr, isSelected: false }));
      setLoanRequestList(list);
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    }
  };

  const init = async () => {
    try {
      setGlobalLoading(true);
      await Promise.all([getBranchList(), getSellersList()]);
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    } finally {
      setGlobalLoading(false);
    }
  };

  useEffect(() => { init(); }, []);

  return {
    globalLoading,
    filterLoading,
    filterFormMap,
    branchList,
    sellerList,
    selectedFilters,
    loanRequestList,
    doSelectAll,
    doSelect,
    sendOfferModalShow,
    clearFilters,
    sendWhatsapp,
    downloadDocs,
    generatePaymentVoucher,
    getAccountStatus,
    sendPaymentVoucher,
    sendOfferLoanModalShow,
    openModalOffer,
    setOpenModalOffer,
    loansOffer,
    sendOffer,
  };
};

export default useLoanRequestsController;
