import { Fragment } from 'react';
import { Button, CardActions, CardContent } from '@doit/pcnt-react-ui-library';
import { Divider, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Card from '../../components/commons/Card';
import useCustomerDataController from '../../hooks/controllers/useCustomerDataController';
import StepHeader from './components/StepHeader';
import Loading from '../../components/commons/Loading';
import i18n from '../../common/i18n';
import { customerDataSchemaValidation } from '../../forms/customerDataSchemaValidations';
import CustomerDataInputText from './components/CustomerDataInputText';
import CustomerDataComboBox from './components/CustomerDataComboBox';
import CustomerDataTypeAhead from './components/CustomerDataTypeAhead';
import CustomerDataDebitCardInput from './components/CustomerDataDebitCardInput';
import CheckboxControlled from '../../components/commons/CheckboxControlled';
import TextInputControlled from '../../components/commons/TextInputControlled';
import StepsLoanRequest from './components/StepsLoanRequest';

const CustomerDataPage = () => {
  const {
    globalLoading,
    loanRequest,
    inputGroups,
    resolveToShowConditional,
    resolveToShowUserInputIfAlreadyHasValue,
    onSelectOption,
    sendValidationCode,
    verificateCode,
    mustLoadDebitCardInfo,
    hasDebitCardValid,
    doSubmit,
    ctaLoading,
  } = useCustomerDataController();

  const methods = useForm({
    resolver: yupResolver(customerDataSchemaValidation(inputGroups)),
  });

  if (globalLoading) {
    return <Loading />;
  }

  return (
    <>
      <StepsLoanRequest stepActive={i18n.StepsLoanRequest[2]} />
      <Card>
        <CardContent>
          <StepHeader loanRequest={loanRequest} />
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(doSubmit)}>
              {inputGroups.map((inputGroup, index) => (
                <Fragment key={inputGroup.name}>
                  <Typography align="left" sx={{ marginBottom: '32px', marginTop: index !== 0 ? '32px' : '0px' }}>
                    <b>{inputGroup.name}</b>
                  </Typography>
                  <Grid container key={inputGroup.name} columnGap="24px" rowGap="32px">
                    {inputGroup.variables.map((input) => (
                      <Fragment key={input.name}>
                        {input.userInputOptions.type === 'TEXT' && resolveToShowConditional(input) && (
                          <CustomerDataInputText
                            input={input}
                            methods={methods}
                            onSendValidationCode={(inputAux) => sendValidationCode(inputAux, methods)}
                            onInputCode={(code, inputAux) => verificateCode(code, inputAux, methods)}
                          />
                        )}
                        {input.userInputOptions.type === 'CHECKBOX' && resolveToShowConditional(input) && (
                          <Grid item xs="auto">
                            <CheckboxControlled
                              name={input.name}
                              label={input.userInputOptions.label}
                              defaultValue={!!input.userInputOptions.defaultValue}
                            />
                          </Grid>
                        )}
                        {(input.userInputOptions.type === 'COMBO' || input.userInputOptions.type === 'RADIO')
                        && resolveToShowConditional(input) && resolveToShowUserInputIfAlreadyHasValue(input) && (
                          <CustomerDataComboBox
                            input={input}
                            inputGroup={inputGroup}
                            index={index}
                            methods={methods}
                            onSelectOption={onSelectOption}
                          />
                        )}
                        {input.userInputOptions.type === 'TYPEAHEAD' && resolveToShowConditional(input) && (
                          <CustomerDataTypeAhead input={input} methods={methods} />
                        )}
                        {input.userInputOptions.type === 'DATE' && resolveToShowConditional(input) && (
                          <Grid item xs="auto">
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                              <DatePicker
                                name={input.name}
                                label={input.userInputOptions.label}
                                defaultValue={input.userInputOptions.defaultValue}
                              />
                            </LocalizationProvider>
                          </Grid>
                        )}
                        {input.userInputOptions.type === 'TEXT_AREA' && resolveToShowConditional(input) && (
                          <Grid item xs="auto">
                            <TextInputControlled
                              name={input.name}
                              label={input.userInputOptions.label}
                              defaultValue={input.userInputOptions.defaultValue}
                              variant="outlined"
                              helperText={input.userInputOptions.hint}
                              mandatory={input.userInputOptions.required}
                              multiline
                              rows={4}
                              fullWidth
                            />
                          </Grid>
                        )}
                        {input.userInputOptions.type === 'CARD' && resolveToShowConditional(input) && (
                          <Grid item xs={12}>
                            <CustomerDataDebitCardInput
                              input={input}
                              loanRequest={loanRequest}
                              mustLoadDebitCardInfo={mustLoadDebitCardInfo || false}
                              hasDebitCardValid={hasDebitCardValid || false}
                            />
                          </Grid>
                        )}
                      </Fragment>
                    ))}
                  </Grid>
                  {index < inputGroups.length - 1 && <Divider sx={{ marginTop: '32px' }} />}
                </Fragment>
              ))}
              <CardActions>
                <Button type="submit" color="grey" loading={ctaLoading}>{i18n.CustomerData.cta}</Button>
              </CardActions>
            </form>
          </FormProvider>
        </CardContent>
      </Card>
    </>
  );
};

export default CustomerDataPage;
