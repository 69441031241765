import { useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';

const useWebChat = () => {
  const { hasWebChat } = useAuth();

  const init = () => {
    if (hasWebChat()) {
      const js = document.createElement('script');
      js.type = 'text/javascript';
      js.async = true;
      js.src = 'https://go.botmaker.com/rest/webchat/p/QGSO3UX518/init.js';
      document.body.appendChild(js);
    }
  };

  useEffect(init, []);
};

export default useWebChat;
