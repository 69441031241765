import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { publicRoutes, privateRoutes } from '../constants/routesConstants';
import i18n from '../common/i18n';
import { useToaster } from '../contexts/ToasterContext';
import { isExpired } from '../api/loanRequestApiService';

const useNavigation = () => {
  const navigate = useNavigate();
  const { showToast } = useToaster();
  const [queryParams] = useSearchParams();

  const goToLoginPage = () => {
    navigate({
      pathname: publicRoutes.login,
      search: queryParams ? queryParams.toString() : '',
    });
  };

  const goHome = () => {
    navigate({ pathname: privateRoutes.home });
  };

  const navigation = (path, state) => {
    navigate({
      pathname: path,
      search: queryParams ? queryParams.toString() : '',
    }, { state });
  };

  const goToQuotation = () => {
    navigation(privateRoutes.newLoanRequest);
  };

  const goToInstallmentPlan = (hash, state) => {
    navigation(`/plan-cuotas/${hash}`, state);
  };

  const goToCustomerData = (hash, state) => {
    navigation(`/cargar-datos/${hash}`, state);
  };

  const goToUploadDocuments = (hash, state) => {
    navigation(`/cargar-documentos/${hash}`, state);
  };

  const goToSignature = (hash, state) => {
    navigation(`/firma/${hash}`, state);
  };

  const goToFinal = (hash, state) => {
    navigation(`/final/${hash}`, state);
  };

  const getNextStep = (loanRequest) => {
    if (!loanRequest.installmentAmountSelected) {
      return '/plan-cuotas/';
    }
    if (!loanRequest.customerDataCompleted) {
      return '/cargar-datos/';
    }
    if (!loanRequest.documentsCompleted) {
      return '/cargar-documentos/';
    }
    if (!loanRequest.hasSigned && loanRequest.isPaperless) {
      return '/firma/';
    }
    return '/final/';
  };

  // UTILIZADO CUANDO AL INTENTAR GENERAR UNA OFERTA, SE OBTIENE UNA SOLICITUD EXISTENTE PARA CONTINUAR
  const continueExistingLoanRequest = (loanRequest) => {
    if (!loanRequest.requestedAmount || !loanRequest.installmentAmountSelected) {
      navigation(`/plan-cuotas/${loanRequest.hashKey}`);
    } else if (!loanRequest.customerDataCompleted) {
      navigation(`/cargar-datos/${loanRequest.hashKey}`);
    } else if (!loanRequest.hasSigned && loanRequest.isPaperless) {
      navigation(`/firma/${loanRequest.hashKey}`);
    } else if (!loanRequest.documentsCompleted) {
      navigation(`/cargar-documentos/${loanRequest.hashKey}`);
    } else {
      navigation(`/final/${loanRequest.hashKey}`);
    }
  };

  // UTILIZADO PARA CONTINUAR SOLICITUDE DESDE EL LISTADO DE SOLICITUDES
  const resumeLoanRequest = async (loanRequest) => {
    try {
      if (!loanRequest.editable) {
        showToast(i18n.LoanRequestsPage.notEditable, 'error');
      } else {
        const { data: isLoanExpired } = await isExpired(loanRequest.hash);
        if (isLoanExpired) {
          showToast(i18n.LoanRequestsPage.expiredLoan, 'error');
        } else if (loanRequest.productToPay?.showcaseType && loanRequest.productToPay?.product?.productAttributes?.productAmount) {
          showToast(i18n.LoanRequestsPage.loanPaymentLink, 'info');
        } else if (!loanRequest.hasRequestedAmount || !loanRequest.hasInstallmentPlan) {
          navigation(`/plan-cuotas/${loanRequest.hash}`);
        } else if (!loanRequest.hasDocumentsCompleted) {
          if (!loanRequest.hasCustomerDataCompleted) {
            navigation(`/cargar-datos/${loanRequest.hash}`);
          } else {
            navigation(`/cargar-documentos/${loanRequest.hash}`);
          }
        } else if (!loanRequest.hasSigned) {
          navigation(`/firma/${loanRequest.hash}`);
        } else {
          navigation(`/final/${loanRequest.hash}`);
        }
      }
    } catch (error) {
      showToast(i18n.LoanRequestsPage.continueLoanError, 'error');
    }
  };

  const verifyHasDistanceSellingLink = (loanRequest) => {
    if (loanRequest.productToPay?.showcaseType && loanRequest.productToPay?.product?.productAttributes?.productAmount) {
      showToast(i18n.LoanRequestsPage.loanPaymentLink, 'info');
      navigate(privateRoutes.loanRequests);
    }
  };

  return {
    goToLoginPage,
    goHome,
    navigation,
    resumeLoanRequest,
    verifyHasDistanceSellingLink,
    continueExistingLoanRequest,
    goToQuotation,
    goToInstallmentPlan,
    goToCustomerData,
    goToUploadDocuments,
    goToSignature,
    goToFinal,
    getNextStep,
  };
};

export default useNavigation;
