import { useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import {
  Box,
  Collapse, Divider, Grid, IconButton, ListItemText, Menu, MenuItem, TableCell, TableRow, Typography,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CircleIcon from '@mui/icons-material/Circle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import dayjs from 'dayjs';
import { Checkbox } from '@doit/pcnt-react-ui-library';
import {
  STATUS_ACT,
  STATUS_CAN,
  STATUS_COLOUR_STATUS, STATUS_DESCRIPTION, STATUS_LIQ, STATUS_PEN, STATUS_PRE,
} from '../../../constants/loanRequestStatusConstants';
import i18n from '../../../common/i18n';
import { BRANCH_CASH_ID } from '../../../constants/commerceContants';
import { moneyFormatter } from '../../../utils/formatterUtils';
import { fillMessageWith } from '../../../utils/functionsUtils';

const LoanRequestRow = ({
  loanRequest, index, onSelect, onContinue, onSendWhatsapp, onDownloadDocs,
  onPaymentVoucher, onAccountStatus, onSendPaymentVoucher, onSendOffer, onDistanceSelling,
}) => {
  // Para mostrar o no el boton de menu con acciones
  const ACTIONS_STATUS = [STATUS_PEN, STATUS_PRE, STATUS_LIQ, STATUS_ACT, STATUS_CAN];

  const [open, setOpen] = useState();
  const [anchorEl, setAnchorEl] = useState();

  const openMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl();
  };

  const getExpireDate = () => {
    const expireDate = dayjs(loanRequest.expireDate).add(7, 'day').diff(dayjs(), 'day');
    let message = '';
    if (expireDate === 0) {
      message = 'hoy';
    } else if (expireDate > 1) {
      message = `en ${expireDate} días`;
    } else {
      message = `en ${expireDate} día`;
    }
    return message;
  };

  return (
    <>
      <TableRow sx={{ backgroundColor: open ? '#E0E0E0' : index % 2 === 0 ? '#ffffff' : '#f5f5f5' }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {loanRequest.status === STATUS_PEN && (loanRequest.campaignSent === false || loanRequest.campaignSent === undefined) && (
            <Checkbox value={loanRequest.isSelected} checked={!!loanRequest.isSelected} onChange={(event) => onSelect(event, loanRequest)} />
          )}
        </TableCell>
        <TableCell><Typography sx={{ fontSize: '14px' }}>{dayjs(loanRequest.creationDate).format('DD/MM/YYYY')}</Typography></TableCell>
        <TableCell><Typography sx={{ fontSize: '14px' }}>{loanRequest.customerId}</Typography></TableCell>
        <TableCell>
          <Typography sx={{ fontSize: '14px', whiteSpace: 'nowrap', textTransform: 'capitalize' }}>
            {`${loanRequest.customerName.toLowerCase()} ${loanRequest.customerLastname.toLowerCase()}`}
          </Typography>
        </TableCell>
        <TableCell><Typography sx={{ fontSize: '14px' }}>{loanRequest.sappLoanId}</Typography></TableCell>
        <TableCell>
          <Typography sx={{ fontSize: '14px', textTransform: 'capitalize' }}>
            {loanRequest.subProductDescription.toLowerCase()}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography sx={{ fontSize: '14px' }}>
            {moneyFormatter(loanRequest.requestedAmount > 0 ? loanRequest.requestedAmount : loanRequest.maxAmount)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            sx={{
              fontSize: '14px', display: 'flex', alignItems: 'center', whiteSpace: 'nowrap',
            }}
          >
            <CircleIcon
              style={{ fontSize: '10px', marginRight: '4px' }}
              sx={{ color: STATUS_COLOUR_STATUS[loanRequest.status] }}
            />
            {STATUS_DESCRIPTION[loanRequest.status]}
          </Typography>
        </TableCell>
        {ACTIONS_STATUS.includes(loanRequest.status) ? (
          <TableCell>
            <IconButton
              id={`${loanRequest.sappLoanId}-basic-button`}
              aria-controls={openMenu ? `${loanRequest.sappLoanId}-basic-button` : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? 'true' : undefined}
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': `${loanRequest.sappLoanId}-basic-button`,
              }}
            >
              {/* CONTINUAR SOLICITUD */}
              {(loanRequest.status === STATUS_PEN || loanRequest.status === STATUS_PRE) && (
                <MenuItem onClick={() => { onContinue(loanRequest); handleClose(); }}>
                  <ListItemText>{i18n.LoanRequestsPage.actions[0]}</ListItemText>
                </MenuItem>
              )}
              {/* CHATEAR POR WHATSAPP */}
              {loanRequest.cellNumber && (loanRequest.status === STATUS_PEN || loanRequest.status === STATUS_PRE) && (
                <MenuItem onClick={() => { onSendWhatsapp(loanRequest); handleClose(); }}>
                  <ListItemText>{i18n.LoanRequestsPage.actions[1]}</ListItemText>
                </MenuItem>
              )}
              {/* DESCARGAR DOCUMENTACION */}
              {(loanRequest.status === STATUS_PRE || loanRequest.status === STATUS_LIQ
              || loanRequest.status === STATUS_ACT || loanRequest.status === STATUS_CAN) && (
                <MenuItem onClick={() => { onDownloadDocs(loanRequest); handleClose(); }}>
                  <ListItemText>{i18n.LoanRequestsPage.actions[2]}</ListItemText>
                </MenuItem>
              )}
              {/* CUPONERA DE PAGOS */}
              {(loanRequest.status === STATUS_ACT) && (
                <MenuItem onClick={() => { onPaymentVoucher(loanRequest); handleClose(); }}>
                  <ListItemText>{i18n.LoanRequestsPage.actions[3]}</ListItemText>
                </MenuItem>
              )}
              {/* ESTADO DE CUENTA */}
              {(loanRequest.status === STATUS_ACT) && (
                <MenuItem onClick={() => { onAccountStatus(loanRequest); handleClose(); }}>
                  <ListItemText>{i18n.LoanRequestsPage.actions[4]}</ListItemText>
                </MenuItem>
              )}
              {/* ENVIAR CUPONERA DE PAGO POR SMS */}
              {(loanRequest.status === STATUS_PRE || loanRequest.status === STATUS_LIQ) && (
                <MenuItem onClick={() => { onSendPaymentVoucher(loanRequest); handleClose(); }}>
                  <ListItemText>{i18n.LoanRequestsPage.actions[5]}</ListItemText>
                </MenuItem>
              )}
              {/* ENVIAR RECORDATORIO OFERTA ACTUAL */}
              {(loanRequest.status === STATUS_PEN && (loanRequest.campaignSent === false || loanRequest.campaignSent === undefined)) && (
                <MenuItem onClick={() => { onSendOffer(loanRequest); handleClose(); }}>
                  <ListItemText>{i18n.LoanRequestsPage.actions[6]}</ListItemText>
                </MenuItem>
              )}
              {/* GENERAR VENTA A DISTANCIA */}
              {((loanRequest.status === STATUS_PEN || loanRequest.status === STATUS_PRE) && loanRequest.commerceId !== BRANCH_CASH_ID) && (
                <MenuItem onClick={() => { onDistanceSelling(loanRequest); handleClose(); }}>
                  <ListItemText>{i18n.LoanRequestsPage.actions[7]}</ListItemText>
                </MenuItem>
              )}
            </Menu>
          </TableCell>
        ) : (
          <TableCell />
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open}>
            <Grid container>
              <Grid item xs={4} display="flex" alignItems="center">
                <Box sx={{ marginTop: '42px', marginBottom: '42px', paddingLeft: '32px' }}>
                  {loanRequest.status === STATUS_PEN && (
                    <Typography sx={{ fontSize: '14px' }}>
                      {fillMessageWith(i18n.LoanRequestsPage.expireIn, getExpireDate())}
                    </Typography>
                  )}
                  <Typography sx={{ fontSize: '14px' }}>
                    {parse(fillMessageWith(i18n.LoanRequestsPage.branch, loanRequest.commerceName))}
                  </Typography>
                  <Typography sx={{ fontSize: '14px' }}>
                    {parse(fillMessageWith(i18n.LoanRequestsPage.seller, loanRequest.sellerName))}
                  </Typography>
                </Box>
                <Divider orientation="vertical" sx={{ marginLeft: '32px', height: '50%' }} />
              </Grid>
              <Grid item xs={4} display="flex" alignItems="center">
                <Box sx={{ marginTop: '42px', marginBottom: '42px', paddingLeft: '32px' }}>
                  <Typography sx={{ fontSize: '14px' }}>
                    {parse(fillMessageWith(i18n.LoanRequestsPage.cellphone, loanRequest.cellNumber || ''))}
                  </Typography>
                  <Typography sx={{ fontSize: '14px' }}>
                    {parse(fillMessageWith(i18n.LoanRequestsPage.email, loanRequest.email || ''))}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} display="flex" alignItems="center">
                <Divider orientation="vertical" sx={{ height: '50%' }} />
                <Box sx={{ marginTop: '42px', marginBottom: '42px', paddingLeft: '32px' }}>
                  {loanRequest.requestedAmount > 0 ? (
                    <>
                      <Typography sx={{ fontSize: '14px' }}>
                        {parse(fillMessageWith(i18n.LoanRequestsPage.requestedAmount, moneyFormatter(loanRequest.requestedAmount)))}
                      </Typography>
                      <Typography sx={{ fontSize: '14px' }}>
                        {parse(fillMessageWith(i18n.LoanRequestsPage.installments, loanRequest.installments))}
                      </Typography>
                      <Typography sx={{ fontSize: '14px' }}>
                        {parse(fillMessageWith(i18n.LoanRequestsPage.installments, moneyFormatter(loanRequest.installmentAmount)))}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography sx={{ fontSize: '14px' }}>
                        {parse(fillMessageWith(i18n.LoanRequestsPage.requestedAmount, moneyFormatter(loanRequest.maxAmount)))}
                      </Typography>
                    </>
                  )}
                  <Typography sx={{ fontSize: '14px' }}>
                    {parse(fillMessageWith(i18n.LoanRequestsPage.earlyCancellation, loanRequest.prePaidAmount ? 'Si' : 'No'))}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

LoanRequestRow.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  onSendWhatsapp: PropTypes.func.isRequired,
  onDownloadDocs: PropTypes.func.isRequired,
  onPaymentVoucher: PropTypes.func.isRequired,
  onAccountStatus: PropTypes.func.isRequired,
  onSendPaymentVoucher: PropTypes.func.isRequired,
  onSendOffer: PropTypes.func.isRequired,
  onDistanceSelling: PropTypes.func.isRequired,
};

export default LoanRequestRow;
