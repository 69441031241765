import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import {
  Box, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import { Button } from '@doit/pcnt-react-ui-library';
import { fillMessageWith } from '../../../utils/functionsUtils';
import i18n from '../../../common/i18n';
import { getAvailableAmount } from '../../../utils/loanRequestUtils';
import { moneyFormatter } from '../../../utils/formatterUtils';

const InstallmentPlanHeaderSection = ({ loanRequest, onReload }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  return (
    <>
      {isMobile ? (
        <Box sx={{ textAlign: 'left' }}>
          <Typography align="left" sx={{ fontSize: '24px', fontWeight: 700 }}>
            {parse(fillMessageWith(
              i18n.InstallmentPlanPage.title,
              getAvailableAmount(loanRequest) > 0 ? moneyFormatter(getAvailableAmount(loanRequest)) : '-',
            ))}
          </Typography>
          <Button
            variant="tertiary"
            color="grey"
            endIcon={<CachedIcon />}
            onClick={onReload}
            sx={{ marginTop: '12px' }}
            className="minimalist"
          >
            {i18n.InstallmentPlanPage.ctaReload}
          </Button>
        </Box>
      ) : (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography align="left" sx={{ fontSize: '24px', fontWeight: 700 }}>
            {parse(fillMessageWith(
              i18n.InstallmentPlanPage.title,
              getAvailableAmount(loanRequest) > 0 ? moneyFormatter(getAvailableAmount(loanRequest)) : '-',
            ))}
          </Typography>
          <Button variant="tertiary" color="grey" endIcon={<CachedIcon />} onClick={onReload}>
            {i18n.InstallmentPlanPage.ctaReload}
          </Button>
        </Box>
      )}
    </>
  );
};

InstallmentPlanHeaderSection.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
  onReload: PropTypes.func.isRequired,
};

export default InstallmentPlanHeaderSection;
