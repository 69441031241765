import { useMediaQuery, useTheme } from '@mui/material';
import SettlementsDesktop from './components/SettlementsDesktop';
import SettlementsMobile from './components/SettlementsMobile';
import useSettlementsController from '../../hooks/controllers/useSettlementsController';
import Loading from '../../components/commons/Loading';

const SettlementsPage = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const {
    loading,
    settlementList,
    downloadBatchSettlement,
    searchLoans,
    loadingSearch,
    loanList,
    downloadReport,
    loadingDownload,
    abortLoan,
    loadingCancel,
    canceledOk,
    setCanceledOk,
  } = useSettlementsController();

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {isMobile ? (
        <SettlementsMobile
          settlementList={settlementList}
          onDownloadSettlement={downloadBatchSettlement}
          onDownloadLoanList={downloadReport}
          onSearch={searchLoans}
          loadingSearch={loadingSearch}
          loanList={loanList}
          loadingDownload={loadingDownload}
          onCancel={abortLoan}
          loadingCancel={loadingCancel}
          canceledOk={canceledOk}
          setCanceledOk={setCanceledOk}
        />
      ) : (
        <SettlementsDesktop
          settlementList={settlementList}
          onDownloadSettlement={downloadBatchSettlement}
          onDownloadLoanList={downloadReport}
          onSearch={searchLoans}
          loadingSearch={loadingSearch}
          loanList={loanList}
          loadingDownload={loadingDownload}
          onCancel={abortLoan}
          loadingCancel={loadingCancel}
          canceledOk={canceledOk}
          setCanceledOk={setCanceledOk}
        />
      )}
    </>
  );
};

export default SettlementsPage;
