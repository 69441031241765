import PropTypes from 'prop-types';
import { useState } from 'react';
import { CardContent } from '@doit/pcnt-react-ui-library';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, Typography } from '@mui/material';
import Card from '../../../components/commons/Card';
import i18n from '../../../common/i18n';
import SettlementsCompleted from './SettlementsCompleted';
import SettledLoans from './SettledLoans';

const SettlementsDesktop = ({
  settlementList, onDownloadSettlement, onSearch, loadingSearch, loanList,
  onDownloadLoanList, loadingDownload, onCancel, loadingCancel, canceledOk, setCanceledOk,
}) => {
  const [currentTab, setCurrentTab] = useState('1');
  return (
    <>
      <Card>
        <CardContent>
          <Typography align="left" sx={{ fontSize: '20px', fontWeight: 700 }}>{i18n.SettlementsPage.title}</Typography>
          {/* TABS */}
          <TabContext value={currentTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '32px' }}>
              <TabList onChange={(e, value) => setCurrentTab(value)} aria-label="lab API tabs example">
                <Tab label={i18n.SettlementsPage.tabs[0]} value="1" />
                <Tab label={i18n.SettlementsPage.tabs[1]} value="2" />
              </TabList>
            </Box>
            {/* TAB LIQUIDACIONES REALIZADAS */}
            <TabPanel
              value="1"
              style={{
                display: 'flex', paddingTop: '0px', paddingBottom: '0px', paddingLeft: '0px', paddingRight: '0px',
              }}
            >
              <Box sx={{ marginTop: '32px', width: '100%' }}>
                <SettlementsCompleted settlementList={settlementList} onDownload={onDownloadSettlement} />
              </Box>
            </TabPanel>
            {/* TAB CREDITOS LIQUIDADOS */}
            <TabPanel
              value="2"
              style={{
                display: 'flex', paddingTop: '0px', paddingBottom: '0px', paddingLeft: '0px', paddingRight: '0px',
              }}
            >
              <Box sx={{ marginTop: '32px', width: '100%' }}>
                <SettledLoans
                  onSearch={onSearch}
                  loadingSearch={loadingSearch}
                  loanList={loanList}
                  onDownload={onDownloadLoanList}
                  loadingDownload={loadingDownload}
                  onCancel={onCancel}
                  loadingCancel={loadingCancel}
                  canceledOk={canceledOk}
                  setCanceledOk={setCanceledOk}
                />
              </Box>
            </TabPanel>
          </TabContext>
        </CardContent>
      </Card>
    </>
  );
};

SettlementsDesktop.propTypes = {
  settlementList: PropTypes.arrayOf(PropTypes.shape()),
  loanList: PropTypes.arrayOf(PropTypes.shape()),
  onDownloadSettlement: PropTypes.func.isRequired,
  onDownloadLoanList: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  loadingSearch: PropTypes.bool,
  loadingDownload: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  loadingCancel: PropTypes.bool,
  canceledOk: PropTypes.bool,
  setCanceledOk: PropTypes.func.isRequired,
};

SettlementsDesktop.defaultProps = {
  settlementList: [],
  loanList: [],
  loadingSearch: false,
  loadingDownload: false,
  loadingCancel: false,
  canceledOk: false,
};

export default SettlementsDesktop;
