import { fetch } from './axiosInstance';

export const generateBiometricOnboardingData = (hash) =>
  fetch({
    url: `/workflow/4id/${hash}?newOnboarding=true`,
    method: 'get',
  });

export const validateBiometricResult = (externalId, txid) =>
  fetch({
    url: `/workflow/4id/externalid/${externalId}/txid/${txid}`,
    method: 'post',
  });

export const getStepBiometricData = (externalId) =>
  fetch({
    url: `/workflow/4id/externalid/${externalId}`,
    method: 'get',
  });
