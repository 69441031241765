import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {
  IconButton,
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import i18n from '../../../common/i18n';
import { moneyFormatter } from '../../../utils/formatterUtils';

const SettlementsCompleted = ({ settlementList, onDownload }) => (
  <>
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            {i18n.SettlementsPage.settlementsCompletedTableHeaders.map((header) => (
              <TableCell key={header}>
                <Typography sx={{ fontWeight: '600', fontSize: '14px', whiteSpace: 'nowrap' }}>{header}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {settlementList.map((settlement, index) => (
            <TableRow key={settlement.lotId} sx={{ backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f5' }}>
              <TableCell><Typography sx={{ fontSize: '14px' }}>{settlement.lotId}</Typography></TableCell>
              <TableCell><Typography sx={{ fontSize: '14px' }}>{dayjs(settlement.liqDate).format('DD/MM/YYYY')}</Typography></TableCell>
              <TableCell><Typography sx={{ fontSize: '14px' }}>{settlement.liqMethod}</Typography></TableCell>
              <TableCell><Typography sx={{ fontSize: '14px' }}>{moneyFormatter(settlement.totalSettled)}</Typography></TableCell>
              <TableCell><IconButton onClick={() => onDownload(settlement.lotId)}><DownloadIcon fontSize="small" /></IconButton></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </>
);

SettlementsCompleted.propTypes = {
  settlementList: PropTypes.arrayOf(PropTypes.shape()),
  onDownload: PropTypes.func.isRequired,
};

SettlementsCompleted.defaultProps = {
  settlementList: [],
};

export default SettlementsCompleted;
