import PropTypes from 'prop-types';
import { Card, CardActionArea } from '@doit/pcnt-react-ui-library';
import { Box, Typography } from '@mui/material';

// eslint-disable-next-line arrow-body-style
const UploadDocumentCard = ({ document, onClick, disabled }) => {
  return (
    <>
      <Card
        sx={{
          marginTop: '12px',
          border: document.userInputOptions.defaultValue ? '1px solid #00875A' : '1px solid #E0E0E0',
          borderRadius: '8px',
          boxShadow: 'none',
          color: disabled ? '#c3c3c3' : '#424242',
        }}
      >
        <CardActionArea onClick={onClick} disabled={disabled}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box
              sx={{
                paddingLeft: '24px', minHeight: '72px', display: 'flex', alignItems: 'center',
              }}
            >
              <img src={`/images/${document.name}.svg`} alt="upload" style={{ opacity: disabled ? 0.5 : 1 }} />
              <Box sx={{ marginLeft: '18px' }}>
                <Typography
                  align="left"
                  sx={{
                    fontSize: '16px', fontWeight: 600, display: 'flex', alignItems: 'center',
                  }}
                >
                  {document.userInputOptions.label}
                  {document.userInputOptions.defaultValue && <img src="/images/check-icon.svg" alt="check" style={{ marginLeft: '8px' }} />}
                </Typography>
                <Typography align="left" sx={{ fontSize: '12px' }}>{document.userInputOptions.hint}</Typography>
              </Box>
            </Box>
            <img src="/images/upload-icon.svg" alt="upload" style={{ paddingRight: '24px', opacity: disabled ? 0.5 : 1 }} />
          </Box>
        </CardActionArea>
      </Card>
    </>
  );
};

UploadDocumentCard.propTypes = {
  document: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

UploadDocumentCard.defaultProps = {
  disabled: false,
};

export default UploadDocumentCard;
