import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import {
  Box, FormControl, Grid, Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { Alert, Button } from '@doit/pcnt-react-ui-library';
import { yupResolver } from '@hookform/resolvers/yup';
import i18n from '../../../common/i18n';
import TextInputControlled from '../../../components/commons/TextInputControlled';
import { sendLinkSchemaValidation } from '../../../forms/documentUploadSchemaValidations';
import ComboBoxControlled from '../../../components/commons/ComboBoxControlled';
import useRedirectController from '../../../hooks/controllers/useRedirectController';

const LoanDocumentSendContinueLink = ({ loanRequest, onSendLink, loading }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  const { goNextStep } = useRedirectController();

  const methods = useForm({
    resolver: yupResolver(sendLinkSchemaValidation),
  });

  return (
    <FormControl sx={{ width: '100%', marginTop: '32px' }}>
      <Typography align="left" sx={{ fontSize: '14px', marginBottom: '24px' }}>
        {i18n.DocumentUploadPage.tab1.description}
      </Typography>
      <Grid container columnGap="0px" rowGap="20px" sx={{ backgroundColor: '#F5F5F5', padding: '12px 24px', width: 'fit-content' }}>
        {i18n.DocumentUploadPage.tab1.steps.map((step, index) => (
          <Grid
            item
            xs={3}
            key={step.description}
            sx={{
              display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', textAlign: { xs: 'center', md: 'left' },
            }}
          >
            <img src={step.icon} alt={`paso_${index}`} />
            <Typography sx={{ fontSize: '12px', marginLeft: '8px' }}>{step.description}</Typography>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ marginTop: '24px' }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSendLink)} style={{ textAlign: 'left' }}>
            <Grid container columnGap="24px" rowGap="24px">
              <Grid item xs={12} md="auto">
                <TextInputControlled
                  name="cellphone"
                  label={i18n.DocumentUploadPage.tab1.cellphoneInput}
                  helperText={i18n.DocumentUploadPage.tab1.cellphoneInputHint}
                  variant="outlined"
                  fullWidth={isMobile}
                  mandatory
                />
              </Grid>
              <Grid item xs={12} md="auto">
                <ComboBoxControlled
                  name="mode"
                  label={i18n.DocumentUploadPage.tab1.sendMode}
                  options={i18n.DocumentUploadPage.tab1.modeOptions}
                  mandatory
                />
              </Grid>
            </Grid>
            {loanRequest.loadDocumentsByCustomer ? (
              <>
                <Alert severity="info" sx={{ marginTop: '26px' }}>
                  <Typography align="left" sx={{ fontSize: '16px', fontWeight: 700 }}>
                    {i18n.DocumentUploadPage.tab1.loadDocumentsByCustomer[0]}
                  </Typography>
                  <Typography align="left" sx={{ fontSize: '14px' }}>{parse(i18n.DocumentUploadPage.tab1.loadDocumentsByCustomer[1])}</Typography>
                </Alert>
                <Box sx={{ marginTop: '32px', textAlign: 'right' }}>
                  <Button
                    variant="primary"
                    color="grey"
                    fullWidth={isMobile}
                    onClick={() => goNextStep()}
                    loading={loading}
                  >
                    {i18n.DocumentUploadPage.tab1.refresh}
                  </Button>
                </Box>
              </>
            ) : (
              <Box sx={{ marginTop: '24px', textAlign: 'right' }}>
                <Button type="submit" variant="secondary" fullWidth={isMobile} loading={loading}>{i18n.DocumentUploadPage.tab1.ctaSendLink}</Button>
              </Box>
            )}
          </form>
        </FormProvider>
      </Box>
    </FormControl>
  );
};

LoanDocumentSendContinueLink.propTypes = {
  loanRequest: PropTypes.shape().isRequired,
  onSendLink: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

LoanDocumentSendContinueLink.defaultProps = {
  loading: false,
};

export default LoanDocumentSendContinueLink;
