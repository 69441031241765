import { useEffect } from 'react';
import { Grid } from '@mui/material';
import Loading from '../../components/commons/Loading';
import useBiometric from '../../hooks/useBiometric';

const BiometricResultPage = () => {
  const { processCallbackAndGetResult } = useBiometric();

  const init = () => {
    processCallbackAndGetResult();
  };

  useEffect(init, []);
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Loading />
        </Grid>
      </Grid>
    </>
  );
};

export default BiometricResultPage;
