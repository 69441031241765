import { FormProvider, useForm } from 'react-hook-form';
// import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Grid,
  Typography,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@doit/pcnt-react-ui-library';
import i18n from '../../../common/i18n';
import TextInputControlled from '../../../components/commons/TextInputControlled';
import ComboBoxControlled from '../../../components/commons/ComboBoxControlled';
import TypeAheadControlled from '../../../components/commons/TypeAheadControlled';
import { manageBranchesSchemaValidation } from '../../../forms/manageBranchesSchemaValidations';

const EditUserBranchForm = ({
  open,
  onClose,
  onSubmit,
  seller,
  branchList,
}) => {
  const branch = {
    id: seller.branchId,
    label: seller.branch,
  };
  const editSubmit = (data) => {
    const dataSubmit = data;
    dataSubmit.id = seller.id;
    onSubmit(dataSubmit);
    onClose();
  };
  const methods = useForm({
    resolver: yupResolver(manageBranchesSchemaValidation),
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      slotProps={{
        backdrop: {
          sx: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
        },
      }}
      PaperProps={{
        sx: {
          borderRadius: 4,
          padding: 2,
        },
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={(methods.handleSubmit(editSubmit))}>
          <Grid container rowGap="24px" justifyContent="space-between" sx={{ paddingLeft: '32px', paddingRight: '32px', marginTop: '24px' }}>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: '20px', fontWeight: '700' }}>
                {i18n.EditUserModal.title}
              </Typography>
            </Grid>
            <Grid item xs={12} md={5.71}>
              <TypeAheadControlled
                name="branch"
                label={i18n.EditUserModal.inputBranch}
                options={branchList}
                defaultValue={branch}
                disableClearable
                mandatory
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={5.71}>
              <ComboBoxControlled
                sx={{ width: '100%' }}
                name="profile"
                defaultValue={seller.profile}
                label={i18n.EditUserModal.inputProfile}
                options={i18n.EditUserModal.roles}
                disableClearable
                mandatory
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3.6}>
              <TextInputControlled
                name="name"
                defaultValue={seller.firstname}
                label={i18n.EditUserModal.inputName}
                variant="outlined"
                fullWidth
                mandatory
              />
            </Grid>
            <Grid item xs={12} md={3.6}>
              <TextInputControlled
                name="surname"
                defaultValue={seller.lastname}
                label={i18n.EditUserModal.inputSurname}
                variant="outlined"
                fullWidth
                mandatory
              />
            </Grid>
            <Grid item xs={12} md={3.6}>
              <TextInputControlled
                name="identifier"
                defaultValue={seller.identificationNumber}
                label={i18n.EditUserModal.inputIdentifier}
                helperText={i18n.EditUserModal.inputIdentifierHint}
                variant="outlined"
                fullWidth
                mandatory
              />
            </Grid>
            <Grid item xs={12} md={5.71}>
              <TextInputControlled
                name="phone"
                defaultValue={seller.phone}
                label={i18n.EditUserModal.inputPhone}
                helperText={i18n.EditUserModal.inputPhoneHint}
                variant="outlined"
                fullWidth
                mandatory
              />
            </Grid>
            <Grid item xs={12} md={5.71}>
              <TextInputControlled
                name="email"
                defaultValue={seller.email}
                label={i18n.EditUserModal.inputEmail}
                variant="outlined"
                fullWidth
                mandatory
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-evenly" marginTop="30px">
            <Grid item xs={12} md={5}>
              <Button onClick={onClose} variant="tertiary" color="grey" fullWidth>
                {i18n.EditUserModal.secondaryButton}
              </Button>
            </Grid>
            <Grid item xs={12} md={5} sx={{ paddingTop: { xs: '10px' } }}>
              <Button type="submit" variant="primary" color="grey" fullWidth>{i18n.EditUserModal.primaryButton}</Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Dialog>
  );
};

EditUserBranchForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  seller: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    identificationNumber: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    branchId: PropTypes.string.isRequired,
    branch: PropTypes.string.isRequired,
    profile: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
  }).isRequired,
  branchList: PropTypes.arrayOf(PropTypes.shape()),
};
EditUserBranchForm.defaultProps = {
  branchList: [],
};

export default EditUserBranchForm;
