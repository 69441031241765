import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Box,
  FormControl, Grid, InputLabel, MenuItem, Select, Typography,
} from '@mui/material';
import { Divider } from '@doit/pcnt-react-ui-library';
import i18n from '../../../common/i18n';
import SettlementsCompleted from './SettlementsCompleted';
import SettledLoans from './SettledLoans';

const SettlementsMobile = ({
  settlementList, onDownloadSettlement, onSearch, loadingSearch, loanList,
  onDownloadLoanList, loadingDownload, onCancel, loadingCancel, canceledOk, setCanceledOk,
}) => {
  const [selectedOption, setSelectedOption] = useState(i18n.SettlementsPage.tabs[0]);
  return (
    <>
      <Grid container sx={{ paddingLeft: '24px', paddingRight: '24px', backgroundColor: '#FFF' }}>
        <Grid item xs={12}>
          <Typography align="left" sx={{ fontSize: '20px', fontWeight: 700 }}>{i18n.SettlementsPage.title}</Typography>
          <FormControl fullWidth sx={{ marginTop: '24px' }}>
            <InputLabel id="opt-select-label">{i18n.DocumentUploadPage.selectOption}</InputLabel>
            <Select
              labelId="opt-select-label"
              id="opt-select"
              value={selectedOption}
              label={i18n.DocumentUploadPage.selectOption}
              onChange={(e) => setSelectedOption(e.target.value)}
            >
              {i18n.SettlementsPage.tabs.map((option) => (
                <MenuItem value={option} key={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedOption === i18n.SettlementsPage.tabs[0] && (
            <Box sx={{ marginTop: '24px' }}>
              <SettlementsCompleted settlementList={settlementList} onDownload={onDownloadSettlement} />
            </Box>
          )}
          {selectedOption === i18n.SettlementsPage.tabs[1] && (
            <>
              <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
              <SettledLoans
                onSearch={onSearch}
                loadingSearch={loadingSearch}
                loanList={loanList}
                onDownload={onDownloadLoanList}
                loadingDownload={loadingDownload}
                onCancel={onCancel}
                loadingCancel={loadingCancel}
                canceledOk={canceledOk}
                setCanceledOk={setCanceledOk}
              />
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

SettlementsMobile.propTypes = {
  settlementList: PropTypes.arrayOf(PropTypes.shape()),
  loanList: PropTypes.arrayOf(PropTypes.shape()),
  onDownloadSettlement: PropTypes.func.isRequired,
  onDownloadLoanList: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  loadingSearch: PropTypes.bool,
  loadingDownload: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  loadingCancel: PropTypes.bool,
  canceledOk: PropTypes.bool,
  setCanceledOk: PropTypes.func.isRequired,
};

SettlementsMobile.defaultProps = {
  settlementList: [],
  loanList: [],
  loadingSearch: false,
  loadingDownload: false,
  loadingCancel: false,
  canceledOk: false,
};

export default SettlementsMobile;
