import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  getDocuments, getLoanRequest, getQrMobileUrlBack, getUrlForUpload,
  sendMsgUploadDocuments,
  sendSmsContinueLinkToCustomer,
  sendSMSUploadImages,
  sendWhatsappContinueLinkToCustomer,
} from '../../api/workflowApiService';
import { useToaster } from '../../contexts/ToasterContext';
import { getApiErrorMessage } from '../../services/errorApiService';
import { DNI_BARCODE_NOT_FOUND, DNI_BARCODE_NOT_FOUND_EXCEED_LIMIT, SELFIE_REJECTED_BY_RENAPER } from '../../constants/apiErrorCodeConstants';
import i18n from '../../common/i18n';
import useNavigation from '../useNavigation';

const useDocumentUploadController = () => {
  const { hash } = useParams();
  const { verifyHasDistanceSellingLink } = useNavigation();

  const { showToast } = useToaster();
  const [globalLoading, setGlobalLoading] = useState(true);

  const [loanRequest, setLoanRequest] = useState();

  const [QR, setQR] = useState();
  const [requiredDocuments, setRequiredDocuments] = useState([]);
  const [uploadImageLoading, setUploadImageLoading] = useState(false);

  // modo de continuar la solicitud: enviar link o continuar aqui
  const [selectedOption, setSelectedOption] = useState(i18n.DocumentUploadPage.tabOptions[1]);

  // tipo de documento a subir: DNI frente/dorso, selfie
  const [documentToUpload, setDocumentToUpload] = useState();

  // imagen cargada
  const [image, setImage] = useState();

  // Se habilita el botón de continuar si tiene todos los documentos requeridos cargados
  const [canContinue, setCanContinue] = useState();

  const [sendLinkLoading, setSendLinkLoading] = useState(false);

  const init = async () => {
    try {
      setGlobalLoading(true);
      window.scrollTo(0, 0);
      const { data: lr } = await getLoanRequest(hash);
      setLoanRequest(lr);

      // Verifica si tiene generado link de venta a distancia
      verifyHasDistanceSellingLink(lr);

      const { data: qrURL } = await getQrMobileUrlBack(hash);
      setQR(qrURL);

      const { data: requiredDocumentsAux } = await getDocuments(hash);
      setRequiredDocuments(requiredDocumentsAux);

      const hasRequiredDocumentIncomplete = requiredDocumentsAux.find((doc) => doc.userInputOptions.required && !doc.userInputOptions.defaultValue);
      setCanContinue(!hasRequiredDocumentIncomplete);
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    } finally {
      setGlobalLoading(false);
    }
  };

  const doUploadDocument = async (imageFile) => {
    try {
      setUploadImageLoading(true);
      await getUrlForUpload(hash, documentToUpload.name, imageFile);
      setDocumentToUpload(undefined);
      setImage(undefined);
      init();
    } catch (error) {
      if (error?.data?.errorCode === SELFIE_REJECTED_BY_RENAPER) {
        // TODO: redireccionar a la pantalla de error de biometria
      } else if (error?.data?.errorCode === DNI_BARCODE_NOT_FOUND || error?.data?.errorCode === DNI_BARCODE_NOT_FOUND_EXCEED_LIMIT) {
        showToast(getApiErrorMessage(error), 'error');
        init();
      } else {
        showToast(getApiErrorMessage(error), 'error');
      }
    } finally {
      setUploadImageLoading(false);
    }
  };

  const sendLink = async (data) => {
    try {
      setSendLinkLoading(true);
      if (data.mode === i18n.DocumentUploadPage.tab2.modeOptions[0].id) {
        // SMS
        await sendSMSUploadImages(hash, data.cellphone);
      } else if (data.mode === i18n.DocumentUploadPage.tab2.modeOptions[1].id) {
        // Whatsapp
        await sendMsgUploadDocuments(hash, data.cellphone);
      }
      showToast(i18n.DocumentUploadPage.tab2.messageLinkSent, 'success');
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    } finally {
      setSendLinkLoading(false);
    }
  };

  const sendLinkToCustomer = async (data) => {
    try {
      setSendLinkLoading(true);
      if (data.mode === i18n.DocumentUploadPage.tab1.modeOptions[0].id) {
        // SMS
        const { data: lr } = await sendSmsContinueLinkToCustomer(hash, data.cellphone);
        setLoanRequest(lr);
      } else if (data.mode === i18n.DocumentUploadPage.tab1.modeOptions[1].id) {
        // Whatsapp
        const { data: lr } = await sendWhatsappContinueLinkToCustomer(hash, data.cellphone);
        setLoanRequest(lr);
      }
      showToast(i18n.DocumentUploadPage.tab2.messageLinkSent, 'success');
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    } finally {
      setSendLinkLoading(false);
    }
  };

  useEffect(() => { init(); }, []);
  return {
    globalLoading,
    loanRequest,
    QR,
    requiredDocuments,
    doUploadDocument,
    uploadImageLoading,
    selectedOption,
    setSelectedOption,
    documentToUpload,
    setDocumentToUpload,
    image,
    setImage,
    canContinue,
    sendLink,
    sendLinkLoading,
    sendLinkToCustomer,
  };
};

export default useDocumentUploadController;
