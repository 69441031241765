import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import {
  Dialog, Grid, IconButton, Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Close } from '@mui/icons-material';
import { Button } from '@doit/pcnt-react-ui-library';
import i18n from '../../../common/i18n';
import { fillMessageWith } from '../../../utils/functionsUtils';
import { capitalizeEachWord, moneyFormatter } from '../../../utils/formatterUtils';

const SettlementsCancelDialog = ({
  loan, open, onClose, onConfirm, loading, canceledOk,
}) => (
  <>
    <Dialog open={open}>
      <Grid container sx={{ padding: '32px', paddingTop: '24px' }}>
        <Grid item xs={12} sx={{ textAlign: 'right' }}><IconButton sx={{ zIndex: 100 }} onClick={onClose}><Close /></IconButton></Grid>
        {canceledOk ? (
          <>
            <Grid item xs={12}>
              <Typography
                align="left"
                sx={{
                  fontSize: '20px', fontWeight: 700, display: 'flex', alignItems: 'center',
                }}
              >
                <CheckCircleIcon sx={{ color: '#00875A', marginRight: '8px' }} />
                {i18n.SettlementsPage.Dialog.successTitle}
              </Typography>
              <Typography align="left" sx={{ fontSize: '14px', marginTop: '8px' }}>{i18n.SettlementsPage.Dialog.successDescription}</Typography>
              <Button
                variant="primary"
                color="grey"
                onClick={onClose}
                fullWidth
                sx={{ marginTop: '40px' }}
              >
                {i18n.SettlementsPage.Dialog.closeCTA}
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Typography align="left" sx={{ fontSize: '20px', fontWeight: 700 }}>{i18n.SettlementsPage.Dialog.title}</Typography>
              <Typography align="left" sx={{ fontSize: '14px', marginTop: '8px' }}>{i18n.SettlementsPage.Dialog.description}</Typography>
              <Typography align="left" sx={{ fontSize: '14px', marginTop: '16px' }}>
                {parse(fillMessageWith(i18n.SettlementsPage.Dialog.loan, loan.sappLoanId))}
              </Typography>
              <Typography align="left" sx={{ fontSize: '14px', marginTop: '8px' }}>
                {parse(fillMessageWith(i18n.SettlementsPage.Dialog.customerName, capitalizeEachWord(loan.customerName.toLowerCase())))}
              </Typography>
              <Typography align="left" sx={{ fontSize: '14px', marginTop: '8px' }}>
                {parse(fillMessageWith(i18n.SettlementsPage.Dialog.customerId, loan.customerId))}
              </Typography>
              <Typography align="left" sx={{ fontSize: '14px', marginTop: '8px' }}>
                {parse(fillMessageWith(i18n.SettlementsPage.Dialog.amount, moneyFormatter(loan.requestedAmount)))}
              </Typography>
            </Grid>
            <Grid xs={6} sx={{ marginTop: '40px', paddingRight: '12px' }}>
              <Button
                variant="secondary"
                color="grey"
                onClick={onClose}
                fullWidth
                sx={{ minWidth: '0px' }}
              >
                {i18n.SettlementsPage.Dialog.cancelCTA}
              </Button>
            </Grid>
            <Grid xs={6} sx={{ marginTop: '40px', paddingLeft: '12px' }}>
              <Button
                variant="primary"
                color="grey"
                onClick={onConfirm}
                fullWidth
                loading={loading}
                sx={{ minWidth: '0px' }}
              >
                {i18n.SettlementsPage.Dialog.confirmCTA}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Dialog>
  </>
);

SettlementsCancelDialog.propTypes = {
  loan: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  canceledOk: PropTypes.bool,
};

SettlementsCancelDialog.defaultProps = {
  open: false,
  loading: false,
  canceledOk: false,
};

export default SettlementsCancelDialog;
