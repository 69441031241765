import { useEffect, useState } from 'react';
import download from 'downloadjs';
// import dayjs from 'dayjs';
import { useToaster } from '../../contexts/ToasterContext';
import i18n from '../../common/i18n';
import {
  abortLoanApi,
  generateBatchSettlementPdf, generateSettledLoansExcel, getBatchesSettlements, getSettledLoans,
} from '../../api/settlementsApiService';
import { ERROR_ALREADY_EXIST_CANCEL_CREDIT } from '../../constants/apiErrorCodeConstants';
import { getApiErrorCode } from '../../services/errorApiService';

const useSettlementsController = () => {
  const { showToast } = useToaster();
  const [loading, setLoading] = useState(true);
  const [loadingSearch, setSearchLoading] = useState(false);
  const [loadingDownload, setDownloadLoading] = useState(false);
  const [settlementList, setSettlementList] = useState();
  const [loanList, setLoanList] = useState();
  const [loadingCancel, setCancelLoading] = useState(false);
  const [canceledOk, setCanceledOk] = useState(false);

  const downloadBatchSettlement = (settlementId) => {
    try {
      generateBatchSettlementPdf(settlementId);
    } catch (error) {
      showToast(i18n.SettlementsPage.downloadSettlementError, 'error');
    }
  };

  const searchLoans = async (data) => {
    try {
      setSearchLoading(true);
      const filters = {
        dateFrom: new Date(data.dateFrom),
        dateTo: new Date(data.dateTo),
        customerId: data.dni,
        creditId: data.loanId,
      };
      const { data: loanlistAux } = await getSettledLoans(filters);
      setLoanList(loanlistAux.map((loan) => ({
        customerId: loan.customerId,
        sappLoanId: loan.sappLoanId,
        status: loan.status,
        customerName: `${loan.customerLastname} ${loan.customerName.split(' ')[0]}`,
        liqDate: loan.liqDate,
        actDate: loan.actDate,
        requestedAmount: loan.requestedAmount,
        paymentMethod: loan.paymentMethod,
      })));
    } catch (error) {
      showToast(i18n.SettlementsPage.searchError, 'error');
    } finally {
      setSearchLoading(false);
    }
  };

  const downloadReport = async (data) => {
    try {
      setDownloadLoading(true);
      const filters = {
        dateFrom: new Date(data.dateFrom),
        dateTo: new Date(data.dateTo),
        customerId: data.dni,
        creditId: data.loanId,
      };
      const res = await generateSettledLoansExcel(filters);
      // download(res, `reporte_creditos_${dayjs().format('YYYY-MM-DD')}.xlsx`);
      const contentType = res.headers['content-type'] || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

      // Extrae el nombre del archivo (si está en Content-Disposition)
      const contentDisposition = res.headers['content-disposition'];
      const filename = contentDisposition
        ? contentDisposition.split('filename=')[1].replace(/"/g, '')
        : 'default.xlsx';

      // Usa downloadjs para descargar el archivo
      download(res.data, filename, contentType);
    } catch (error) {
      showToast(i18n.SettlementsPage.exportError, 'error');
    } finally {
      setDownloadLoading(false);
    }
  };

  const abortLoan = async (loan) => {
    try {
      setCancelLoading(true);
      await abortLoanApi(loan);
      setCanceledOk(true);
    } catch (error) {
      if (getApiErrorCode(error) === ERROR_ALREADY_EXIST_CANCEL_CREDIT) {
        showToast(i18n.SettlementsPage.cancelAlreadyError, 'error');
      } else {
        showToast(i18n.SettlementsPage.cancelError, 'error');
      }
    } finally {
      setCancelLoading(false);
    }
  };

  const init = async () => {
    try {
      setLoading(true);
      const { data: settlementListAux } = await getBatchesSettlements();
      setSettlementList(settlementListAux.map((settlement) => ({
        lotId: settlement.sappBatchSettlementId,
        liqDate: settlement.liqDate,
        liqMethod: settlement.paymentMethod,
        totalSettled: settlement.totalSettled,
      })));
    } catch (error) {
      showToast(i18n.SettlementsPage.getSettlementsError, 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => { init(); }, []);

  return {
    loading,
    settlementList,
    downloadBatchSettlement,
    searchLoans,
    loadingSearch,
    loanList,
    downloadReport,
    loadingDownload,
    abortLoan,
    loadingCancel,
    canceledOk,
    setCanceledOk,
  };
};

export default useSettlementsController;
