import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useToaster } from '../../contexts/ToasterContext';
import { getApiErrorMessage } from '../../services/errorApiService';
import { getLoanRequest, sendSMSWithDisclaimerURL, signAgreement } from '../../api/workflowApiService';
import i18n from '../../common/i18n';
import { dataURLtoBlob } from '../../utils/functionsUtils';
import useNavigation from '../useNavigation';

const useSignatureController = () => {
  const { hash } = useParams();
  const { goToFinal, verifyHasDistanceSellingLink } = useNavigation();

  const { showToast } = useToaster();
  const [globalLoading, setGlobalLoading] = useState(true);
  const [sendAgreementLoading, setSendAgreementLoading] = useState();
  const [signLoading, setSignLoading] = useState();
  const [signature, setSignature] = useState();
  const [loanRequest, setLoanRequest] = useState();

  const sendAgreement = async () => {
    try {
      setSendAgreementLoading(true);
      await sendSMSWithDisclaimerURL(hash);
      showToast(i18n.SignaturePage.agreementSentMessage, 'info');
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    } finally {
      setSendAgreementLoading(false);
    }
  };

  const sigPadRef = (ref) => {
    if (ref && !ref.isEmpty()) {
      setSignature(ref.toDataURL());
    } else {
      setSignature(undefined);
    }
  };

  const doSignAgreement = async () => {
    try {
      setSignLoading(false);
      const blob = dataURLtoBlob(signature);
      const file = new File([blob], 'urlDataAsBlob.png');
      await signAgreement(hash, file);
      goToFinal(hash);
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    } finally {
      setSignLoading(false);
    }
  };

  const init = async () => {
    try {
      setGlobalLoading(true);
      window.scrollTo(0, 0);
      const { data: lr } = await getLoanRequest(hash);
      setLoanRequest(lr);
      // Verifica si tiene generado link de venta a distancia
      verifyHasDistanceSellingLink(lr);
    } catch (error) {
      showToast(getApiErrorMessage(error), 'error');
    } finally {
      setGlobalLoading(false);
    }
  };

  useEffect(() => { init(); }, []);

  return {
    globalLoading,
    loanRequest,
    sendAgreement,
    sendAgreementLoading,
    sigPadRef,
    signature,
    signLoading,
    doSignAgreement,
  };
};

export default useSignatureController;
