import {
  Checkbox, FormControl, InputLabel, ListItemText,
  MenuItem, OutlinedInput, Select,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultiSelectControlled = ({
  name, label, options, ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      {...props}
      name={name}
      control={control}
      defaultValue={[]}
      render={({ field }) => (
        <FormControl sx={{ width: '100%' }}>
          <InputLabel id={`${name}-multiple-checkbox-label`}>{label}</InputLabel>
          <Select
            labelId={`${name}-multiple-checkbox-label`}
            id={`${name}-multiple-checkbox`}
            multiple
            value={field.value}
            onChange={field.onChange}
            input={<OutlinedInput label={label} />}
            renderValue={(selected) => options.filter((item) => selected.includes(item.id)).map((item) => item.label).join(', ')}
            MenuProps={MenuProps}
          >
            {options.map((opt) => (
              <MenuItem key={opt.id} value={opt.id}>
                <Checkbox checked={field.value.includes(opt.id)} />
                <ListItemText primary={opt.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};

MultiSelectControlled.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  label: PropTypes.string.isRequired,
};

export default MultiSelectControlled;
