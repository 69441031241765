export const STATUS_PEN = 'PEN';
export const STATUS_PRE = 'PRE';
export const STATUS_LIQ = 'LIQ';
export const STATUS_ACT = 'ACT';
export const STATUS_REC = 'REC';
export const STATUS_VTO = 'VTO';
export const STATUS_CAN = 'CAN';
export const STATUS_ANU = 'ANU';
export const STATUS_DES = 'DES';

export const STATUS_LIST_FILTER = [
  { id: STATUS_PEN, label: 'Pendientes' },
  { id: STATUS_PRE, label: 'Pre Aprobadas' },
  { id: STATUS_LIQ, label: 'A liquidar' },
  { id: STATUS_ACT, label: 'Activas' },
  { id: STATUS_REC, label: 'Rechazadas' },
  { id: STATUS_VTO, label: 'Vencidas' },
  { id: STATUS_CAN, label: 'Canceladas' },
  { id: STATUS_ANU, label: 'Anuladas' },
  { id: STATUS_DES, label: 'Desistidas' },
];

export const STATUS_DESCRIPTION = {
  [STATUS_PEN]: 'Pendiente',
  [STATUS_PRE]: 'Pre Aprobada',
  [STATUS_LIQ]: 'A liquidar',
  [STATUS_ACT]: 'Activa',
  [STATUS_REC]: 'Rechazada',
  [STATUS_VTO]: 'Vencida',
  [STATUS_CAN]: 'Cancelada',
  [STATUS_ANU]: 'Anulada',
  [STATUS_DES]: 'Desistida',
};

export const STATUS_COLOUR_STATUS = {
  [STATUS_PEN]: '#F5A623',
  [STATUS_PRE]: '#02C66A',
  [STATUS_LIQ]: '#02C66A',
  [STATUS_ACT]: '#02C66A',
  [STATUS_REC]: '#D0013F',
  [STATUS_VTO]: '#D0013F',
  [STATUS_CAN]: '#02C66A',
  [STATUS_ANU]: '#D0013F',
  [STATUS_DES]: '#D0013F',
};
